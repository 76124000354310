@import '../../styles/colors.scss';

.BetrixScoreMatrix {
    position: relative;
    width: 100%;
    border: 1px solid $grey;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .matrix-row {
        display: flex;
        position: relative;
        z-index: 1;

        // &:last-child {
        //     div {
        //         border-bottom: 1px solid transparent;

        //         &.selected {
        //             border-bottom: 1px solid $green;
        //         }

        //         &.insured {
        //             border-bottom: 1px solid $orange;
        //         }
        //     }
        // }

        &:nth-of-type(odd) {
            div {
                &:nth-of-type(odd) {
                    background: rgba(0, 0, 0, 0.1);
                }
            }
        }

        &:nth-of-type(even) {
            div {
                &:nth-of-type(even) {
                    background: rgba(0, 0, 0, 0.1);
                }
            }
        }

        div {
            flex: 1;
            height: 2.5rem;
            line-height: 2.5rem;
            // border-right: 1px solid $grey;
            // border-bottom: 1px solid $grey;
            color: $white;
            font-size: 0.9rem;
            text-align: center;

            // &:last-child {
            //     border-right: 1px solid transparent;

            //     &.selected {
            //         border-right: 1px solid $green;
            //     }

            //     &.insured {
            //         border-right: 1px solid $orange;
            //     }
            // }

            &.selected {
                background: rgba(0, 145, 135, 0.8) !important;
            }

            &.insured {
                background: rgba(243, 139, 0, 0.8) !important;
            }
        }
    }
}