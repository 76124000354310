@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';

.regions-list {
    .region {
        margin-bottom: 30px;

        @include xxl {
            margin-bottom: 25px;
        }

        @include xl {
            margin-bottom: 20px;
        }

        @include l {
            margin-bottom: 15px;   
        }

        @include lg {
            margin-bottom: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
        
        .region-title {
            display: flex;
            align-items: center;
            padding: 7px 15px;
            border-bottom: 1px solid $black;
            background: $darkGrey;

            @include xxl {
                padding: 7px 12px;
            }

            @include xl {
                padding: 6px 10px;
            }

            @include l {
                padding: 5px 8px;
            }

            @include lg {
                padding: 7px 10px;
                border-bottom: 0;
            }

            img {
                width: 30px;
                height: 30px;
                margin-right: 10px;
                border-radius: 20px;
                object-fit: cover;

                @include xxl {
                    width: 27px;
                    height: 27px;
                }

                @include xl {
                    width: 24px;
                    height: 24px;
                    margin-right: 8px;
                }

                @include l {
                    width: 21px;
                    height: 21px;
                }

                @include lg {
                    width: 20px;
                    height: 20px;
                }
            }

            i {
                margin-right: 10px;
                color: $white;
                font-size: 30px;

                @include xxl {
                    font-size: 27px;
                }

                @include xl {
                    margin-right: 8px;
                    font-size: 24px;
                }

                @include l {
                    font-size: 21px;
                }

                @include lg {
                    font-size: 20px;
                }
            }

            h3 {
                margin: 0;
                color: $white;
                font-weight: 700;
                font-size: 15px;

                @include xxl {
                    font-size: 14px;
                }

                @include xl {
                    font-size: 13px;
                }

                @include l {
                    font-size: 12px;
                }
            }
        }
    }
}