@import '../../styles/mixins.scss';

.sport-match {
    position: relative;
    .flag {position: absolute; right: 0px; top: 40px; height: 25px; border: 1px solid #999}
    .markets-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;

        @include xxl {
            grid-gap: 8px;
        }

        @include xl {
            grid-gap: 6px;
        }

        @include l {
            grid-gap: 5px;
        }

        @include lg {
            display: block;
        }
    }
}