@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';

.MarketGroupSelector {
    margin: 0 0 1.25rem;
    padding: 0;
    border-bottom: 2px solid $orange;
    list-style-type: none;

    @include lg {
        margin: 1.25rem 0 0.75rem;
        white-space: nowrap;
        overflow-x: auto;
    }

    li {
        display: inline-block;
        margin-right: 1.25rem;
        padding: 0.35rem 0.7rem;
        border: 2px solid $orange;
        border-bottom: 0;
        color: $white;
        font-size: 0.95rem;
        font-weight: 700;
        text-transform: uppercase;
        cursor: pointer;

        @include lg {
            margin-right: 0;
            font-size: 0.8rem;
            padding: 0.3rem 0.6rem;
        }

        &:last-of-type {
            margin-right: 0;
        }

        &:hover,
        &.active {
            background: $orange;
        }
    }
}