@import '../../styles/colors.scss';

.InfoTooltip {
    position: relative;
    display: inline-block;
    
    > i {
        color: $white;
        font-size: 14px;
        cursor: pointer;

        &:hover {
            ~ span {
                opacity: 1;
            }
        }
    }

    > span {
        display: block;
        position: absolute;
        top: 120%;
        left: 0;
        width: 150px;
        padding: 3px 5px;
        background: $black;
        color: $white;
        font-size: 14px;
        opacity: 0;
    }
}