@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';

.BetslipItemData {
    width: 100%;

    .InfoTooltip {
        margin-left: 10px;

        @include xl {
            margin-left: 8px;   
        }

        @include lg {
            margin-left: 10px;
        }
    }

    .outcome-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        @include xl {
            margin-bottom: 4px;
        }

        @include l {
            margin-bottom: 2px;
        }

        @include lg {
            margin-bottom: 5px;
        }

        .outcome-value {
            color: $green;
            font-size: 16px;
            font-weight: 700;

            @include xxl {
                font-size: 15px;
            }

            @include xl {
                font-size: 14px;           
            }

            @include l {
                font-size: 13px;           
            }

            @include lg {
                font-size: 16px;
            }
        }


        > i {
            margin: 0 10px;
            color: $green;
            font-size: 16px;
            cursor: pointer;

            @include xxl {
                font-size: 15px;
            }

            @include xl {
                margin: 0 8px;
                font-size: 14px;           
            }

            @include l {
                font-size: 13px;           
            }

            @include lg {
                margin: 0 10px;
                font-size: 16px;
            }
        }

        .odds-value {
            margin-left: auto;
            color: $orange;
            font-size: 15px;
            font-weight: 700;
            /*
            &.odds-value-oroddsinsured {
                color: $blue; // $red;
                position: absolute;
                top: 30px;
                right: 20px;
                // font-size: 14px;
            }*/
            @include xxl {
                font-size: 14px;
            }

            @include xl {
                font-size: 13px;           
            }

            @include l {
                font-size: 12px;
            }

            @include lg {
                font-size: 15px;
            }
        }
    }

    .scores-wrapper {
        max-height: 0;
        transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
        overflow: hidden;
    
        &.extended {
            max-height: 37.5rem;
            overflow: visible;
        }

        .subitems-any-other {
            margin-top: 0.5rem;
            color: $green;
            text-align: center;

            &.insured {
                color: $orange;
            }

            span {
                &:first-child {
                    margin-right: 0.3rem;
                }
            }
        }
    
        > div {
            &.insured {
                span {
                    color: $orange;
                }
            }
            
            > span {
                color: $green;
                font-size: 1rem;
                font-style: italic;
        
                &:first-child {
                    margin-right: 0.65rem;
                }
            }
        }
    }

    .market-wrapper {
        margin: 5px 0 10px;

        @include xxl {
            margin: 5px 0 8px;
        }

        @include xl {
            margin: 4px 0 6px;
        }

        @include l {
            margin: 2px 0 4px;
        }

        @include lg {
            margin: 5px 0 10px;
        }

        span {
            color: $grey;
            font-size: 16px;
            font-weight: 700;

            @include xxl {
                font-size: 15px;
            }

            @include xl {
                font-size: 14px;           
            }

            @include l {
                font-size: 13px;
            }

            @include lg {
                font-size: 16px;
            }
        }
    }

    .match-wrapper {
        display: flex;
        align-items: center;

        img {
            width: 16px;

            @include xxl {
                width: 15px;
            }

            @include xl {
                width: 14px;           
            }

            @include l {
                width: 13px;           
            }

            @include lg {
                width: 16px;
            }
        }

        span {
            margin-left: 10px;
            color: $white;
            font-size: 16px;
            font-weight: 700;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            @include xxl {
                font-size: 15px;
            }

            @include xl {
                margin-left: 8px;
                font-size: 14px;
            }

            @include l {
                font-size: 13px;
            }

            @include lg {
                margin-left: 10px;
                font-size: 16px;
            }
        }
    }
}