@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';

.base {
    width: 100%;
    overflow: hidden;
    background: #333;
    .base-children {
        position: relative;
        min-height: calc(100vh - 110px);
        margin-top: 110px;
        margin-left: 12%;
        margin-right: 20%;
        background: $black;
        z-index: 2;

        @include fhd {
            min-height: calc(100vh - 90px);
            margin-top: 90px;
        }

        @include xxl {
            min-height: calc(100vh - 80px);
            margin-top: 80px;
        }
    
        @include xl {
            min-height: calc(100vh - 72px);
            margin-top: 72px;
        }
    
        @include l {
            min-height: calc(100vh - 65px);
            margin-top: 65px;
        }

        @include lg {
            position: sticky;
            left: 0;
            width: 100vw;
            min-height: calc(100vh - 60px);
            margin: 60px 0 0;
            transition: margin-left 0.5s ease-in-out;
        }

        .main-banner {
            position: fixed;
            display: block;
            width: 100%;
            height: 200px;
        }

        .main-wrapper {
            position: relative;
            padding: 0 25px;

            @include xxl {
                padding: 0 20px;
            }

            @include xl {
                padding: 0 16px;
            }

            @include l {
                padding: 0 12px;
            }

            @include lg {
                padding: 0;
            }
        }
    }
}