@import '../../../styles/colors.scss';

.bet-betslip-items {
    padding: 10px 15px;

    .fa-check {
        color: $green;
    }

    .fa-times {
        color: $red;
    }
    
    .fa-sign-out-alt {
        color: $orange;
    }

    .fa-undo {
        color: $grey;
    }

    .single-item {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }

        div {
            color: $white;
            font-size: 16px;

            &.sport {
                width: 3%;
            }

            &.region-league {
                width: 18%;

                span {
                    display: block;
                    font-style: italic;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            &.match {
                width: 25%;

                span {
                    display: block;

                    &:nth-of-type(1) {
                        font-weight: 700;
                    }

                    &:nth-of-type(2) {
                        font-size: 14px;
                    }
                }
            }

            &.bet {
                width: 10%;

                span {
                    display: block;
                    color: $green;
                    font-weight: 700;
                    text-align: center;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            &.status {
                width: 3%;
                text-align: center;
            }

            &.stake {
                width: 12%;
                text-align: center;
            }

            &.odds {
                width: 7%;
                text-align: center;
            }

            &.score {
                width: 8%;
                text-align: center;

                span {
                    &:nth-of-type(1),
                    &:nth-of-type(3) {
                        padding: 3px 5px;
                        border: 1px solid $white;
                        border-radius: 5px;
                    }
                    &:nth-of-type(2) {
                        margin: 0 5px;
                        font-weight: 700;
                    }
                }
            }

            &.result {
                width: 5%;
                text-align: center;

                i {
                    font-size: 20px;
                }
            }

            &.payout {
                display: flex;
                align-items: center;
                
                span {
                    margin-left: 10px;
                }
            }

            img {
                display: block;
                width: 20px;
            }
        }

        .fa-search-plus {
            color: $white;
            margin-left: 30px;
        }

        .show-cashout {
            padding: 5px 10px;
            margin-left: auto;
            border: 2px solid $green;
            border-radius: 10px;
            color: $white;
            font-size: 13px;

            &:hover {
                background: $hoverGrey;
            }
        }

        .cashout-wrapper {
            position: absolute;
            top: 0;
            left: 100%;
            padding-left: 30px;

            .input-range {
                -webkit-appearance: none;
                width: 180px;
                height: 10px;
                border-radius: 10px;
                background: $green;
                outline: none;

                &::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    appearance: none;
                    width: 20px;
                    height: 20px;
                    border-radius: 20px;
                    background: $white;
                    cursor: pointer;
                }

                &::-moz-range-thumb {
                    width: 20px;
                    height: 20px;
                    border-radius: 20px;
                    background: $white;
                    cursor: pointer;
                }

                &.disabled {
                    opacity: 0.5;
                    cursor: not-allowed;
                }
            }
            
            .cashout-labels {
                display: flex;
                justify-content: space-between;
                margin-top: 5px;

                &.disabled {
                    opacity: 0.5;
                    cursor: not-allowed;
                }

                span {
                    font-size: 14px;
                }
            }

            .custom-cashout {
                display: flex;
                align-items: center;
                margin-top: 10px;

                span {
                    &:nth-of-type(1) {
                        margin-right: 10px;
                    }
                }

                div {
                    flex: 12 1;

                    input {
                        -moz-appearance: textfield;
                        width: 100%;
                        border-bottom: 1px solid $grey;
                        color: $white;
                        font-size: 16px;
    
                        &::-webkit-outer-spin-button,
                        &::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }
                    }
                }
            }

            .cashout-value {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 15px;

                i {
                    color: $green;
                    font-size: 20px;
                }

                span {
                    margin-left: 10px;
                    color: $green;
                    font-size: 16px;
                    font-weight: 700;
                }
            }

            button {
                width: 100%;
                margin-top: 15px;
                padding: 8px 0;
                border-radius: 10px;
                background: $green;
                color: $white;
                font-size: 15px;

                &:hover {
                    font-weight: 700;
                }
            }
        }
    }

    .combo {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        h5 {
            margin: 0;
            color: $orange;
            font-size: 18px;
            font-weight: 700;
        }

        div {
            display: flex;
            margin-left: 30px;
            color: $white;

            > i {
                margin-left: 0;
                margin-right: 10px;
            }
        }

        i {
            margin-left: 30px;
            font-size: 18px;
        }
    }
}