@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';

.sport-league {
    .grid-layout {
        display: flex;

        .match-column {
            display: flex;
            align-items: center;
            width: calc(30% - 4px);
            margin-right: 4px;

            @include xxl {
                width: calc(30% - 3px);
                margin-right: 3px;
            }

            @include xl {
                width: calc(30% - 2px);
                margin-right: 2px;
            }

            @include lg {
                width: 50%;
                margin-right: 0;
            }
        }

        .columns-equal-width {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
            grid-gap: 4px;
            width: 70%;

            @include xxl {
                grid-gap: 3px;
            }

            @include xl {
                grid-gap: 2px;
            }

            @include lg {
                width: 50%;
            }

            > div {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .no-matches {
        @include lg {
            padding: 0 10px;
        }

        span {
            color: $green;
            font-style: italic;
            font-size: 18px;
            font-weight: 700;
            text-transform: uppercase;

            @include xxl {
                font-size: 16px;
            }

            @include xl {
                font-size: 15px;
            }

            @include l {
                font-size: 14px;   
            }

            @include lg {
                font-size: 16px;
            }
        }
    }
}