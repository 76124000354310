@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';

.popup {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 4;

    .popup-inner {
        position: relative;
        max-height: 85%;
        padding: 1.9rem 3.1rem;
        border: 1px solid $green;
        background: $darkGrey;
        color: $white;
        text-align: center;
        overflow-y: auto;

        @include lg {
            width: 90%;
            max-width: 90%;
            max-height: calc(100vh - 3.15rem);
            padding: 2rem;
        }

        .close-popup {
            position: absolute;
            top: 0.3rem;
            right: 0.3rem;
            color: $white;
            font-size: 1.25rem;
            cursor: pointer;

            @include lg {
                top: 0.25rem;
                font-size: 1rem;
            }
        }

        .text-content {
            color: $white;
            font-size: 1rem;
        }

        .market-info {
            div {
                display: flex;
                align-items: flex-start;
                margin-bottom: 0.5rem;

                span {
                    font-size: 1rem;
                    text-align: left;

                    &:first-of-type {
                        font-weight: 700;
                    }

                    &:last-of-type {
                        margin-left: 0.5rem;
                    }
                }
            }
        }

        .betrix-info-content {
            p {
                margin: 15px 0 0;
                color: $white;
                font-size: 16px;

                @include lg {
                    margin: 10px 0 0;
                    font-size: 14px;
                }

                &:first-child {
                    margin: 0;
                }
            }

            ul {
                margin: 5px 0;
                padding-left: 30px;

                @include lg {
                    margin: 3px 0 0;
                    padding-left: 30px;
                }

                li {
                    color: $white;
                    font-size: 16px;

                    @include lg {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}