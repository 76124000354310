@import '../../styles/colors.scss';

.betslip-history {
    padding: 10px 0;

    > h3 {
        margin: 0 0 5px;
        padding: 0;
        color: $orange;
        font-size: 20px;
    }
}