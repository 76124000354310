@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';

.BetrixComboMultiSystems {
    .BetslipItemData {
        .scores-wrapper {
            div {
                span {
                    font-size: 14px;
                }
            }
        }
    }
    .payouts-container {
        margin: 10px 20px 20px;

        .predictions-row {
            display: flex;
            align-items: center;
            margin-bottom: 1px;

            span {
                width: 70px;
                color: $white;
            }

            .numbers-container {
                display: flex;
                align-items: center;
                width: calc(100% - 70px);
                margin-left: 10px;

                button {
                    flex: 1;
                    margin-right: 1px;
                    padding: 5px 0;
                    color: $white;
                    font-size: 14px;
                    overflow: hidden;

                    &:last-of-type {
                        margin-right: 0;
                    }

                    &.has-background {
                        background: $darkGrey;

                        &:not(:first-of-type) {
                            &:hover {
                                background: $hoverGrey;
                            }
                        }
                    }

                    &.selected {
                        background: $green;
                    }

                    &.insured {
                        background: $orange;
                    }

                    &.colored-selected {
                        color: $green;
                        font-weight: 700;
                    }

                    &.colored-insured {
                        color: $orange;
                    }

                    &.not-clickable {
                        cursor: default;
                    }
                }
            }
        }
    }

    .empty-betslip {
        padding-top: 15px !important;
        padding-bottom: 0 !important;

        @include xxl {
            padding-top: 12px !important;
        }

        @include xl {
            padding-top: 10px !important;
        }

        @include l {
            padding-top: 8px !important;
        }

        @include lg {
            padding-top: 15px !important;
        }
    }
}