@import './colors.scss';
@import './fonts.scss';

body {
    margin: 0;
    padding: 0;
    background: $black;
    font-family: $Roboto;

    &::-webkit-scrollbar {
        width: 5px !important;
    }
    
    &::-webkit-scrollbar-track {
        background: $lightBlack;
    }
    
    &::-webkit-scrollbar-thumb {
        background: $grey;
    }
    
    * {
        box-sizing: border-box;
    }
}

button {
    display: block;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    background: none;
    font-family: $Roboto;
    cursor: pointer;
}

input {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    background: none;
    font-family: $Roboto;
}