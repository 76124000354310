@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';

.date-wrapper {
    margin-bottom: 4px;

    @include xxl {
        margin-bottom: 3px;
    }

    @include xl {
        margin-bottom: 2px;
    }

    @include lg {
        margin-bottom: 1px;
    }

    .date-top-row {
        margin-bottom: 1px;

        @include lg {
            margin-bottom: 0;
        }

        div {
            height: 25px;

            @include xxl {
                height: 24px;
            }

            @include xl {
                height: 23px;
            }

            @include l {
                height: 22px;
            }

            @include lg {
                height: 23px;
            }
            
            span {
                font-size: 14px;

                @include xxl {
                    font-size: 13px;
                }

                @include xl {
                    font-size: 12px;
                }

                @include l {
                    font-size: 11px;
                }

                @include lg {
                    font-size: 12px;
                }
            }
        }

        .date-title {
            background: $lightBlack;
            padding: 0 10px;

            @include xxl {
                padding: 0 8px;
            }

            @include xl {
                padding: 0 6px;
            }

            @include l {
                padding: 0 5px;
            }

            @include lg {
                padding: 0 8px;
            }

            span {
                color: $white;
                font-weight: 700;
            }
        }

        .market-options {
            display: grid !important;
            grid-gap: 1px;
            grid-template-columns: repeat(auto-fit, minmax(0, 1fr));

            span {
                height: 25px;
                line-height: 25px;
                background: $lightBlack;
                color: $green;
                text-align: center;

                @include xxl {
                    height: 24px;
                    line-height: 24px;
                }

                @include xl {
                    height: 23px;
                    line-height: 23px;
                }

                @include l {
                    height: 22px;
                    line-height: 22px;
                }

                @include lg {
                    height: 23px;
                    line-height: 23px;
                }
            }
        }
    }

    .date-match {
        margin-bottom: 1px;

        div {
            height: 25px;

            @include xxl {
                height: 24px;
            }

            @include xl {
                height: 23px;
            }

            @include l {
                height: 22px;   
            }

            @include lg {
                height: 40px;
            }
            
            span {
                font-size: 14px;

                @include xxl {
                    font-size: 13px;
                }

                @include xl {
                    font-size: 12px;
                }

                @include l {
                    font-size: 11px;
                }

                @include lg {
                    font-size: 12px;
                }
            }
        }

        .match-name {
            padding: 0 10px;
            background: $grey;
            text-decoration: none;

            @include xxl {
                padding: 0 8px;
            }

            @include xl {
                padding: 0 6px;
            }

            @include l {
                padding: 0 5px;   
            }

            @include lg {
                padding: 0 8px;
                height: 40px;
            }

            .match-teams {
                display: flex;
                height: auto;
                margin-left: 20px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                @include xxl {
                    margin-left: 16px;
                }

                @include xl {
                    margin-left: 12px;
                }

                @include l {
                    margin-left: 10px;
                }

                @include lg {
                    flex-direction: column;
                    margin-left: 10px;
                }

                span {
                    font-weight: 700;

                    &:nth-of-type(2) {
                        margin: 0 5px;

                        @include xl {
                            margin: 0 4px;
                        }

                        @include lg {
                            display: none;
                        }
                    }
                }
            }
            
            span {
                color: $white;
            }

            i {
                margin-left: auto;
                color: $white;
                font-size: 16px;

                @include xxl {
                    font-size: 15px;
                }

                @include xl {
                    font-size: 14px;
                }

                @include l {
                    font-size: 13px;
                }

                @include lg {
                    font-size: 14px;
                }
            }
        }

        .market-odds {
            display: grid !important;
            grid-gap: 1px;
            grid-template-columns: repeat(auto-fit, minmax(0, 1fr));

            div {
                height: 25px;
                line-height: 25px;
                background: $darkGrey;
                text-align: center;

                @include xxl {
                    height: 24px;
                    line-height: 24px;
                }

                @include xl {
                    height: 23px;
                    line-height: 23px;
                }

                @include l {
                    height: 22px;
                    line-height: 22px;
                }

                @include lg {
                    height: 40px;
                    line-height: 40px;
                }

                span {
                    color: $white;
                }
            }

            .odds-wrapper {
                position: relative;
                display: flex;
                justify-content: center;
                cursor: pointer;

                @include lg {
                    flex-direction: column;
                }

                &.desktop {
                    &.empty {
                        &:hover {
                            background: $hoverGrey;
                        }
                    }
                    &.active {
                        background: $green;

                        .outcome-value {
                            color: $black;
                        }
                    }
                }

                &.mobile {
                    &.active {
                        background: $green;

                        .outcome-value {
                            color: $black;
                        }
                    }
                }

                .odds-value {
                    @include lg {
                        line-height: 40px;
                    }
                }

                .outcome-value {
                    position: absolute;
                    left: 10px;
                    color: $orange;

                    @include xxl {
                        left: 8px;
                    }

                    @include xl {
                        left: 6px;
                    }
                    
                    @include l {
                        left: 5px;
                    }

                    @include lg {
                        margin-right: 0;
                        line-height: 16px;
                    }
                }
            }
        }
    }
}