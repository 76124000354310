@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';

.mobile-keyboard {
    display: none;

    @include lg {
        display: flex;
        justify-content: space-between;
        background: $black;
        max-height: 0;
        transition: max-height 0.5s ease-in-out;

        &.opened {
            max-height: 200px;
        }

        .grid-layout {
            display: grid;
            grid-gap: 1px;
            margin-top: 5px;
            margin-bottom: 5px;
            border-radius: 5px;
            overflow: hidden;

            span {
                padding: 10px 0;
                background: $darkGrey;
                color: $white;
                text-align: center;
                font-size: 16px;
            }
        }

        .left-column {
            grid-template-columns: repeat(3, 1fr);
            width: calc(73% - 5px);
            margin-left: 5px;
        }

        .right-column {
            grid-template-columns: repeat(1, 1fr);
            width: calc(25% - 5px);
            margin-right: 5px;
        }
    }
}