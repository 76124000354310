@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';

.betslip-item {
    position: relative;
    margin-bottom: 1px;
    padding: 10px 20px 10px 35px;
    border-left: 3px solid $lightBlack;
    background: $lightBlack;

    @include xxl {
        padding: 8px 16px 8px 30px;
    }

    @include xl {
        padding: 6px 14px 6px 25px;                   
    }

    @include l {
        padding: 5px 12px 5px 20px;                   
    }

    @include lg {
        padding: 10px 20px 10px 35px;
    }

    &.same-match {
        border-left: 3px solid $red;

        @include xl {
            border-left: 2px solid $red;               
        }

        .same-match-text {
            display: block;
            margin-top: 10px;
            color: $red;
            font-size: 16px;
            text-align: center;

            @include xxl {
                margin-top: 8px;
                font-size: 15px;
            }

            @include xl {
                margin-top: 6px;
                font-size: 14px;           
            }

            @include l {
                font-size: 13px;
            }

            @include lg {
                margin-top: 10px;
                font-size: 16px;
            }
        }
    }

    .remove-item {
        position: absolute;
        top: 10px;
        left: 10px;
        color: $red;
        font-size: 22px;

        @include xxl {
            left: 8px;
            font-size: 20px;
        }

        @include xl {
            top: 7px;
            left: 6px;
            font-size: 18px;               
        }

        @include l {
            top: 6px;
            left: 5px;               
            font-size: 16px;
        }

        @include lg {
            top: 10px;
            left: 10px;
            font-size: 22px;
        }
    }
    
    .outcome-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        @include xxl {
            margin-bottom: 8px;
        }

        @include xl {
            margin-bottom: 6px;               
        }

        @include l {
            margin-bottom: 4px;               
        }

        @include lg {
            margin-bottom: 10px;
        }

        .outcome-value {
            color: $green;

            font-size: 16px;
            font-weight: 700;

            @include xxl {
                font-size: 15px;
            }

            @include xl {
                font-size: 14px;
            }

            @include l {
                font-size: 13px;
            }

            @include lg {
                font-size: 16px;
            }
        }

        .odds-value {
            color: $orange;
            font-size: 15px;
            font-weight: 700;
            /*
            &.odds-value-oroddsinsured {
                color: $blue; // $red;
                position: absolute;
                top: 30px;
                right: 20px;
                // font-size: 14px;
            }*/
            @include xxl {
                font-size: 14px;
            }

            @include xl {
                font-size: 13px;           
            }

            @include l {
                font-size: 12px;
            }

            @include lg {
                font-size: 15px;
            }
        }
    }

    .market-wrapper {
        margin-bottom: 10px;

        @include xxl {
            margin-bottom: 8px;
        }

        @include xl {
            margin-bottom: 6px;               
        }

        @include lg {
            margin-bottom: 10px;
        }

        span {
            color: $grey;
            font-size: 16px;
            font-weight: 700;

            @include xxl {
                font-size: 15px;
            }

            @include xl {
                font-size: 14px;           
            }

            @include l {
                font-size: 13px;
            }

            @include lg {
                font-size: 16px;
            }
        }
    }

    .match-wrapper {
        display: flex;
        align-items: center;

        img {
            width: 16px;

            @include xxl {
                width: 15px;
            }

            @include xl {
                width: 14px;           
            }

            @include l {
                width: 13px;           
            }

            @include lg {
                width: 16px;
            }
        }

        span {
            margin: 0 10px;
            color: $white;
            font-size: 16px;
            font-weight: 700;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            @include xxl {
                font-size: 15px;
            }

            @include xl {
                margin: 0 8px;
                font-size: 14px;
            }

            @include l {
                font-size: 13px;           
            }

            @include lg {
                margin: 0 10px;
                font-size: 16px;
            }
        }

        i {
            color: $green;
            font-size: 16px;
            cursor: pointer;

            @include xxl {
                font-size: 15px;
            }

            @include xl {
                font-size: 14px;           
            }

            @include l {
                font-size: 13px;           
            }

            @include lg {
                font-size: 16px;
            }
        }
    }
/*
    .scores-wrapper {
        margin-top: 5px;
        margin-left: 25px;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);

        @include xl {
            margin-top: 4px;
            margin-left: 22px;               
        }

        @include l {
            margin-top: 2px;
        }

        @include lg {
            margin-top: 5px;
            margin-left: 25px;
        }
    
        &.extended {
            max-height: 500px;
            transition: max-height 0.5s ease-in-out;
        }
    
        div {
            &:not(.InfoTooltip) {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &.insured {
                span {
                    color: $orange;
                }
            }
            
            span {
                color: $green;
                font-size: 16px;
                font-style: italic;

                @include xxl {
                    font-size: 15px;
                }

                @include xl {
                    font-size: 14px;       
                }

                @include l {
                    font-size: 13px;
                }

                @include lg {
                    font-size: 16px;
                }
        
                &:first-child {
                    margin-right: 10px;

                    @include xl {
                        margin-right: 8px;   
                    }

                    @include lg {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
*/
}