@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';

.BetrixComboMode {
    max-height: calc(100% - 163px);
    overflow-y: auto;
    padding-bottom: 20px;
    background: $lightBlack;

    &::-webkit-scrollbar {
        width: 3px !important;
    }
    
    &::-webkit-scrollbar-track {
        background: $darkGrey;
    }
    
    &::-webkit-scrollbar-thumb {
        background: $orange;
    }

    .items-container {
        .item-wrapper {
            position: relative;
            padding: 10px 20px 10px 35px;

            @include xxl {
                padding: 8px 16px 8px 30px;
            }
        
            @include xl {
                padding: 6px 14px 6px 25px;                   
            }
        
            @include l {
                padding: 5px 12px 5px 20px;                   
            }
        
            @include lg {
                padding: 10px 20px 10px 35px;
            }

            &.only-match {
                padding: 5px 20px 5px 35px;

                .fa-times,
                .fa-check {
                    top: 8px;
                    left: 15px;
                    font-size: 16px;
                }

                .disabled-overlay {
                    top: 0;
                    bottom: 0;
                }
            }

            .fa-times,
            .fa-check {
                position: absolute;
                top: 13px;
                left: 13px;
                font-size: 16px;
            }

            .fa-check {
                color: $white;
            }

            .fa-times {
                color: $red;
            }

            .disabled-overlay {
                position: absolute;
                top: 10px;
                bottom: 10px;
                left: 35px;
                right: 20px;
                background: rgba(27, 27, 30, 0.5);
                opacity: 0;
                z-index: -1;

                @include xxl {
                    top: 8px;
                    bottom: 8px;
                    left: 30px;
                    right: 16px;
                }
            
                @include xl {
                    top: 6px;
                    bottom: 6px;
                    left: 25px;
                    right: 14px;               
                }
            
                @include l {
                    top: 5px;
                    bottom: 5px;
                    left: 20px;
                    right: 12px;              
                }
            
                @include lg {
                    top: 10px;
                    bottom: 10px;
                    left: 35px;
                    right: 20px;
                }

                &.active {
                    opacity: 1;
                    z-index: 1;
                }
            }

            .outcome-wrapper {
                margin-bottom: 0;
                position: relative;
                .outcome-value {

                    font-size: 15px;
        
                    @include xxl {
                        font-size: 14px;
                    }
        
                    @include xl {
                        font-size: 13px;
                    }
        
                    @include l {
                        font-size: 12px;
                    }
        
                    @include lg {
                        font-size: 15px;
                    }
                }
        
                .odds-value {
                    /*
                    &.odds-value-oroddsinsured {
                        color: $blue; // $red;
                        position: absolute;
                        top: 30px;
                        right: 20px;
                        // font-size: 14px;
                    }*/
                    font-size: 14px;
        
                    @include xxl {
                        font-size: 13px;
                    }
        
                    @include xl {
                        font-size: 12px;           
                    }
        
                    @include l {
                        font-size: 11px;
                    }
        
                    @include lg {
                        font-size: 14px;
                    }
                }
            }
        
            .market-wrapper {
                margin: 0;
        
                span {
                    font-size: 15px;
        
                    @include xxl {
                        font-size: 14px;
                    }
        
                    @include xl {
                        font-size: 13px;           
                    }
        
                    @include l {
                        font-size: 12px;
                    }
        
                    @include lg {
                        font-size: 15px;
                    }
                }
            }
        
            .match-wrapper {
                img {
                    width: 14px;
        
                    @include xxl {
                        width: 13px;
                    }
        
                    @include xl {
                        width: 12px;           
                    }
        
                    @include l {
                        width: 11px;           
                    }
        
                    @include lg {
                        width: 14px;
                    }
                }
        
                span {
                    margin: 0 5px;
                    font-size: 15px;
        
                    @include xxl {
                        font-size: 14px;
                    }
        
                    @include xl {
                        margin: 0 3px;
                        font-size: 13px;
                    }
        
                    @include l {
                        font-size: 12px;           
                    }
        
                    @include lg {
                        margin: 0 5px;
                        font-size: 15px;
                    }
                }
            }
        }
    }
}