@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';

.BetrixInfoPopup {
    width: 30rem;
    font-size: 1rem;
    text-align: left;

    @include lg {
        width: 100%;
        font-size: 0.9rem;
    }

    p {
        margin: 1rem 0 0;
        color: $white;

        @include lg {
            margin: 0.65rem 0 0;
        }

        &:first-child {
            margin: 0;
        }
    }

    ul {
        margin: 0.3rem 0;
        padding-left: 2rem;

        @include lg {
            margin: 0.25rem 0 0;
        }

        li {
            color: $white;
        }
    }

    .explanation {
        display: flex;
        align-items: center;
        justify-content: center;

        .selection {
            display: flex;
            align-items: center;

            .color {
                background: $green
            }
        }

        .insurance {
            display: flex;
            align-items: center;
            margin-left: 2rem;

            @include lg {
                margin-left: 1rem;
            }

            .color {
                background: $orange
            }
        }

        .text {
            color: $white;
        }
    }
}