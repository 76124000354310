@import '../../styles/mixins.scss';

.CustomMarketBoxes {
    > div {
        > div {
            display: inline-block;
            vertical-align: top;
            margin-right: 0.72rem;
            margin-bottom: 0.72rem;

            @include lg {
                display: block;
                margin-right: 0;
            }

            &:last-of-type {
                margin-right: 0;
            }
        }
    }
    .column-1 {
        > div {
            width: 100%;
        }
    }
    .column-2 {
        > div {
            width: calc(50% - 0.36rem);

            @include lg {
                width: 100%;
            }
        }
    }
    .column-3 {
        > div {
            width: calc(33.33% - 0.48rem);

            @include lg {
                width: 100%;
            }
        }
    }
}