@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
/*
select.scope {
    margin-left: 10px;
    background: #fff;
    border-radius: 3px;
}
*/

.BetrixTopRow {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 1px;
    background: $darkGrey;
/*
    @include lg {
        &.suggestions-shown {
            margin-bottom: 1.6rem;
        }
    }
*/
    @include md {
        display: none;
    }

    @include sm {
        display: flex;
    }

    i {
        color: $white;
        font-size: 1.25rem;
        cursor: pointer;

        @include lg {
            margin-left: 0;
            font-size: 0.9rem;
        }
    }

    @keyframes colorchange {
        0% {
            background: $green;
        }
        50% {
            background: $orange;
        }
        100% {
            background: $green;
        }
    }

    // .explanation {
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: center;
    //     margin-left: 1.25rem;

    //     @include lg {
    //         margin-left: 0.5rem;
    //     }

    //     span {
    //         font-size: 1rem;

    //         @include lg {
    //             font-size: 0.7rem;
    //         }
    //     }

    //     .selection {
    //         display: flex;
    //         align-items: center;

    //         .color {
    //             background: $green
    //         }
    //     }

    //     .insurance {
    //         display: flex;
    //         align-items: center;
    //         margin-top: 0.12rem;

    //         .color {
    //             background: $orange
    //         }
    //     }

    //     .text {
    //         color: $white;
    //     }
    // }

    .market-name {
        margin: 0;
    }

    .reset-button {
        margin-left: auto;
        margin-right: 0.6rem;
        padding: 0.3rem 0.6rem;
        background: $red;
        color: $white;
        font-size: 0.9rem;
        font-weight: 700;

        @include lg {
            margin-right: 0.5rem;
            padding: 0.2rem 0.3rem;
            font-size: 0.65rem;
        }

        &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }

    .show-suggested-markets,
    .hide-suggested-markets {
        min-width: 9.4rem;
        margin-left: 1rem;
        padding: 0.3rem 0.6rem;
        background: $red;
        color: $white;
        font-size: 0.8rem;
        font-weight: 700;

        @include lg {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            max-width: unset;
            height: 1.5rem;
            line-height: 1.5rem;
            margin: 0;
            padding: 0 0.6rem;
            font-size: 0.7rem;
        }
    }

    .hide-suggested-markets {
        background: $grey
    }

    .show-suggested-markets {
        animation: colorchange 2s infinite;
        -webkit-animation: colorchange 2s infinite;
    }

    .invert-button {
        border: 1px solid $white;
        position: absolute;
        padding: 0.25rem 0.5rem 0.2rem;
        top: 11px;
        right: 10px;
        background: $black;
        font-size: 0.9rem;
        span {
            white-space: pre;
            &:nth-of-type(1) {
                color: $white !important;
            }
            &:last-child {
                color: #F38B00;
                font-weight: 700
            }
        }
        &:hover {
            background: $hoverGrey
        }

        @include lg {
            top: 4px
        }
    }

    .multi-selection-label {
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: 1rem;

        @include lg {
            margin-right: 0.75rem;
        }

        span {
            font-size: 0.95rem;
            font-weight: 700;

            @include lg {
                font-size: 0.8rem;
            }

            &:nth-of-type(1) {
                color: $white;
            }

            &:nth-of-type(2) {
                margin-left: 0.5rem;
                color: $orange;
            }
        }
    }
}