@import '../../styles/colors.scss';

.betslip-history-range {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .react-date-picker {
        margin-right: 20px;

        &__wrapper {
            border: 1px solid $orange;
        }

        &__inputGroup {
            input,
            span {
                color: $white;
            }
        }

        &__button {
            svg {
                stroke: $white;
                stroke-width: 1;
            }

            &:hover,
            &:active,
            &:visited,
            &:focus {
                svg {
                    stroke: $white !important;
                }
            }
        }
    }

    > button {
        padding: 8px 15px;
        border: 1px solid $orange;
        border-radius: 5px;
        color: $white;
        font-size: 14px;

        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }

        &:not(:disabled) {
            &:hover {
                background: $orange;
                color: $black;
            }
        }
    }
}