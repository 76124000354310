@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';

.main-matrix-wrapper {
    position: relative;

    @include md {
        height: 100vh;
    }

    @include sm {
        height: auto;
    }

    .reset-icon {
        position: absolute;
        // top: 0.5rem;
        // left: 0.5rem;
        // font-size: 2rem;
        top: 0.2rem;
        left: 0.2rem;
        font-size: 1.4rem;

        color: $red;
        opacity: 1;
        transition: opacity 0.5s ease-in-out;

        @include lg {
            top: 0rem;
            left: 0.1rem;
            font-size: 1rem;
        }

        &.disabled {
            opacity: 0.3;
        }
    }

    .away-goals-side,
    .home-goals-side {
        @include lg {
            display: none;
        }

        span {
            position: absolute;
            font-size: 0.75rem;
        }

        i {
            position: absolute;
            margin-left: 0.25rem;
            font-size: 0.75rem;
        }
    }

    .away-goals-side {
        span {
            top: 0.1rem;
            left: 1.5rem;
        }

        i {
            top: 0.3rem;
            left: 3.1rem;
        }
    }

    .home-goals-side {
        span {
            top: -0.5rem;
            left: -0.3rem;
            transform: rotate(270deg);
        }

        i {
            top: 1rem;
            left: 0.2rem;
        }
    }

    .goals-row {
        display: flex;
        position: relative;

        div {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            background: $lightBlack;
            color: $white;
            font-size: 0.7rem;

            @include lg {
                font-size: 0.55rem;
            }
        }
    }

    .away-goals {
        // margin-left: calc(3rem + 1px);
        margin-left: calc(1.8rem + 1px);

        @include lg {
            margin-left: 2.25rem;
        }

        div {
            height: 1.25rem;
            border-right: 1px solid $black;

            @include lg {
                height: 1rem;
            }
            
            &:last-child {
                border-right: 1px solid transparent;
            }
        }
    }

    .home-goals {
        flex-direction: column;
        position: absolute;
        // top: calc(3rem + 1px);
        top: calc(1.8rem + 1px);
        bottom: 0;
        left: calc(1.75rem + 1px);

        @include lg {
            top: calc(2.25rem + 1px);
            left: calc(1.25rem + 1px);
        }

        div {
            width: 1.25rem;
            border-bottom: 1px solid $black;

            @include lg {
                top: 1rem;
                width: 1rem;
            }

            &:last-child {
                border-bottom: 1px solid transparent;
            }
        }
    }

    .TeamName {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background: $darkGrey;

        span {
            color: $white;
            font-size: 1rem;
            font-weight: 700;

            @include lg {
                font-size: 0.8rem;
            }
        }

        &.away {
            height: 1.75rem;
            line-height: 1.75rem;
            margin-bottom: 1px;
            // margin-left: calc(3rem + 1px);
            margin-left: calc(1.8rem + 1px);
            @include lg {
                height: 1rem;
                line-height: 1rem;
                margin-left: 1.25rem;
            }
        }

        &.home {
            position: absolute;
            // top: calc(3rem + 1px);
            top: calc(1.8rem + 1px);
            bottom: 0;
            left: 0;
            width: 1.75rem;

            @include lg {
                top: calc(1rem + 1px);
                width: 1.25rem;
            }

            span {
                transform: rotate(-90deg);
                white-space: nowrap;
            }
        }

        .goals-setter {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;

            i {
                color: $white;
                font-size: 1rem;

                @include lg {
                    font-size: 0.8rem;
                }
            }
            
            &.away {
                top: 50%;
                right: 1rem;
                transform: translateY(-50%);
                font-size: 0.9rem;

                @include lg {
                    right: 0.5rem;
                }

                span {
                    margin: 0 1rem;

                    @include lg {
                        margin: 0 0.5rem;
                    }
                }
            }

            &.home {
                flex-direction: column-reverse;
                top: 1rem;
                left: 50%;
                transform: translateX(-50%);

                @include lg {
                    top: 0.5rem;
                }

                span {
                    margin: 0.3rem 0;
                }
            }
        }
    }

    .matrix-wrapper {
        position: relative;
        // margin-left: calc(3rem + 1px);
        margin-left: calc(1.8rem + 1px);
        @include lg {
            margin-left: 1.25rem;
        }
        
        @include md {
            height: calc(100% - 1rem);
        }

        @include sm {
            height: auto;
        }

        img {
            display: block;
            width: 100%;

            @include md {
                height: 100%;
            }

            @include sm {
                height: auto;
            }
        }

        .any-other-result-right, .any-other-result-right-2 {
            position: absolute;
            right: 0;
            bottom: 0;
            padding-top: 5px;
            padding-right: 3px;
            text-align: right;
            color: $white;
            font-size: 0.9rem;
            font-weight: 700;
            cursor: pointer
        }
        .any-other-result-right {
            top: 0;
            z-index: 9998;
        }
        .any-other-result-right-2, .any-other-result-left-2 {
            top: 60px;
            z-index: 9999;
        }

        .rightRectangle, .leftRectangle, .rightTriangle, .leftTriangle {
            position: absolute;
            top: 2px;
            z-index: 9998
        }

        .rightRectangle2, .leftRectangle2, .rightTriangle2, .leftTriangle2 {
            position: absolute;
            top: 62px;
            z-index: 9998
        }


        .rightRectangle2.darker, .leftRectangle2.darker {
            background:rgba(0, 0, 0, 0.1)
        }
        
        .rightTriangle2.darker,.leftTriangle2.darker {
            border-top-color: rgba(0, 0, 0, 0.1) !important
        }

        .rightRectangle, .rightRectangle2 {
            right: 0;
            background: $grassgreen;
            cursor: pointer;
            &.selected {
                background: $green;
            }
    
            &.insured {
                background: $orange;
            }
            &:disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }

        .leftRectangle, .leftRectangle2 {
            left: 0;
            background: $grassgreen;
            cursor: pointer;
            &.selected {
                background: $green;
            }
    
            &.insured {
                background: $orange;
            }
            &:disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }
/*
        .any-other-button {
            position: absolute;
            top: 0;
            right: 0;
            width: 14rem;
            padding: 0.5rem 0;
            border: 2px solid $green;
            border-top: none;
            color: $white;
            font-size: 0.9rem;
            font-weight: 700;
    
            @include lg {
                left: 2.25rem;
                width: calc(100% - 2.25rem);
                font-size: 0.8rem;
            }
    
            &.selected {
                background: $green;
            }
    
            &.insured {
                background: $orange;
                border: 2px solid $orange;
                border-top: none;
            }
    
            &:disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }
*/

/*
        .leftRectangle {
            background: $red;
        }

        .leftTriangle {
            border-top-style: solid !important;
        }

        .leftTriangle {
            border-top-color: $red !important;
            left: 0;
        }
*/

        .rightTriangle, .rightTriangle2, .leftTriangle, .leftTriangle2 {
            border-top-style: solid !important;
            cursor: pointer;
            border-top-color: $grassgreen !important;
            &.selected {
                border-top-color: $green !important;
            }
    
            &.insured {
                border-top-color: $orange !important;
            }
            &:disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }

/*
        .leftRectangle {
            -webkit-animation: LEFT-RECTANGLE 1s infinite; 
            -moz-animation:    LEFT-RECTANGLE 1s infinite; 
            -o-animation:      LEFT-RECTANGLE 1s infinite; 
            animation:         LEFT-RECTANGLE 1s infinite;
        }

        .leftTriangle {
            -webkit-animation: LEFT-TRIANGLE 1s infinite; 
            -moz-animation:    LEFT-TRIANGLE 1s infinite; 
            -o-animation:      LEFT-TRIANGLE 1s infinite; 
            animation:         LEFT-TRIANGLE 1s infinite;
        }
*/
        .animated {
            -webkit-animation: BETRIX-ODDS 1s infinite; 
            -moz-animation:    BETRIX-ODDS 1s infinite; 
            -o-animation:      BETRIX-ODDS 1s infinite; 
            animation:         BETRIX-ODDS 1s infinite;
        }

        // @keyframes NAME-YOUR-ANIMATION 
        @-webkit-keyframes BETRIX-ODDS {
            0%, 49% {
                // background-color: rgb(117,209,63);
                color:$red
            }
            50%, 100% {
                // background-color: #e50000;
                color:$orange
            }
        }

        @keyframes BETRIX-ODDS {
            0%, 49% {
                // background-color: rgb(117,209,63);
                color:$red
            }
            50%, 100% {
                // background-color: #e50000;
                color:$orange
            }
        }
/*
        @-webkit-keyframes LEFT-TRIANGLE {
            0%, 49% {
                // background-color: rgb(117,209,63);
                border-top-color:$grassgreen
            }
            50%, 100% {
                // background-color: #e50000;
                border-top-color:$black
            }
        }

        @keyframes LEFT-TRIANGLE {
            0%, 49% {
                // background-color: rgb(117,209,63);
                border-top-color:$grassgreen
            }
            50%, 100% {
                // background-color: #e50000;
                border-top-color:$black
            }
        }
*/
/*
        // @keyframes NAME-YOUR-ANIMATION 
        @-webkit-keyframes LEFT-RECTANGLE {
            0%, 49% {
                // background-color: rgb(117,209,63);
                background-color:$grassgreen
            }
            50%, 100% {
                // background-color: #e50000;
                background-color:$black
            }
        }

        @keyframes LEFT-RECTANGLE {
            0%, 49% {
                // background-color: rgb(117,209,63);
                background-color:$grassgreen
            }
            50%, 100% {
                // background-color: #e50000;
                background-color:$black
            }
        }

        @-webkit-keyframes LEFT-TRIANGLE {
            0%, 49% {
                // background-color: rgb(117,209,63);
                border-top-color:$grassgreen
            }
            50%, 100% {
                // background-color: #e50000;
                border-top-color:$black
            }
        }

        @keyframes LEFT-TRIANGLE {
            0%, 49% {
                // background-color: rgb(117,209,63);
                border-top-color:$grassgreen
            }
            50%, 100% {
                // background-color: #e50000;
                border-top-color:$black
            }
        }
*/
        .any-other-result-right .odds, .any-other-result-right-2 .odds {
            margin-top: 3px;
            margin-bottom: 7px
        }

        .asterisk {
            font-size: 10px;
            cursor: default !important
        }

        .any-other-result-left, .any-other-result-left-2 {
            position: absolute;
            left: 0;
            bottom: 0;
            padding-top: 5px;
            padding-left: 3px;
            text-align: left;
            color: $white;
            font-size: 0.9rem;
            font-weight: 700;
        }
        .any-other-result-left {
            top: 0;
        }


        // .any-other-result-left .result {
            // font-size: 1.3rem;
        // }
        .any-other-result-left-2 {
            cursor: pointer
        }
        .any-other-result-left .odds, .any-other-result-left-2 .odds {
            margin-top: 3px;
            margin-bottom: 7px
        }

        .any-other-result-left .odds {
            color: $orange;
        }

        img.hockey-rink {
            opacity: 0.9
        }

        .row-wrapper {
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            .betrix-row {
                display: flex;
                flex: 1;

                .betrix-item.noPr > div { // trapezoid
                    position: absolute;
                }

                .betrix-item.betrix-item-hockey.ft {
                    .round1 {position: absolute; top: 0; height: 100%; left: 0; right: 55%; border-top-left-radius: 100%}
                    .rectangle1 {position: absolute; top: 0; height: 100%; left: 45%; right: 0}
                    .round2 {position: absolute; top: 0; height: 100%; left: 55%; right: 0; border-top-right-radius: 100%}
                    .rectangle2 {position: absolute; top: 0; height: 100%; left: 0; right: 45%}

                    .round3 {position: absolute; top: 0; height: 100%; left: 0; right: 55%; border-bottom-left-radius: 100%}
                    .rectangle3 {position: absolute; top: 0; height: 100%; left: 45%; right: 0}
                    .round4 {position: absolute; top: 0; height: 100%; left: 55%; right: 0; border-bottom-right-radius: 100%}
                    .rectangle4 {position: absolute; top: 0; height: 100%; left: 0; right: 45%}
                }

                .betrix-item.betrix-item-hockey.noft {
                    .round1 {position: absolute; top: 0; height: 58.5%; left: 0; right: 75%; border-top-left-radius: 100%}
                    .rectangle1 {position: absolute; top: 0; height: 100%; left: 25%; right: 0}
                    .Rectangle1 {position: absolute; top: 58.5%; height: 41.5%; left: 0; right: 75%}

                    .round2 {position: absolute; top: 0; height: 58.5%; left: 75%; right: 0; border-top-right-radius: 100%}
                    .rectangle2 {position: absolute; top: 0; height: 100%; left: 0; right: 25%}
                    .Rectangle2 {position: absolute; top: 58.5%; height: 41.5%; right: 0; left: 75%}

                    .round3 {position: absolute; top: 41.5%; height: 58.5%; left: 0; right: 75%; border-bottom-left-radius: 100%}
                    .rectangle3 {position: absolute; top: 0; height: 100%; left: 25%; right: 0}
                    .Rectangle3 {position: absolute; top: 0; height: 41.5%; left: 0; right: 75%}

                    .round4 {position: absolute; top: 41.5%; height: 58.5%; left: 75%; right: 0; border-bottom-right-radius: 100%}
                    .rectangle4 {position: absolute; top: 0; height: 100%; left: 0; right: 25%}
                    .Rectangle4 {position: absolute; top: 0; height: 41.5%; right: 0; left: 75%}
                }
                .spant span {
                    width: 100%;
                    text-align: center;
                    display: block
                }
                .betrix-item.betrix-item-hockey {
                    &.pr {position: relative}

                    &.selected {
                        .round1, .rectangle1, .round2, .rectangle2, .round3, .rectangle3, .round4, .rectangle4, .Rectangle1, .Rectangle2, .Rectangle3, .Rectangle4 {
                            background: rgba(0, 145, 135, 0.6) !important;
                        }
                    }

                    &.insured {
                        .round1, .rectangle1, .round2, .rectangle2, .round3, .rectangle3, .round4, .rectangle4, .Rectangle1, .Rectangle2, .Rectangle3, .Rectangle4 {
                            background: rgba(243, 139, 0, 0.6) !important;
                        }
                    }
                    &.betrix-item.betrix-item-hockey.betrix-item-hockey1.noft .spans,
                    &.betrix-item.betrix-item-hockey.betrix-item-hockey2.noft .spans,
                    &.betrix-item.betrix-item-hockey.betrix-item-hockey3.noft .spans,
                    &.betrix-item.betrix-item-hockey.betrix-item-hockey4.noft .spans {
                        position: absolute; top: 0; height: 100%; left: 0; width: 100%;
                        span {
                            text-align: center;
                            display: block
                        }
                    }

                    &.betrix-item.betrix-item-hockey.betrix-item-hockey1.ft .spans,
                    &.betrix-item.betrix-item-hockey.betrix-item-hockey2.ft .spans,
                    &.betrix-item.betrix-item-hockey.betrix-item-hockey3.ft .spans,
                    &.betrix-item.betrix-item-hockey.betrix-item-hockey4.ft .spans {
                        position: absolute; top: 0; height: 100%; left: 0; width: 100%;
                        span {
                            text-align: center;
                            display: block
                        }
                    }

                    // this has to work only on desktop
                    &.betrix-item.betrix-item-hockey.betrix-item-hockey1.noft.hockey-desktop .spans,
                    &.betrix-item.betrix-item-hockey.betrix-item-hockey2.noft.hockey-desktop .spans,
                    &.betrix-item.betrix-item-hockey.betrix-item-hockey3.noft.hockey-desktop .spans,
                    &.betrix-item.betrix-item-hockey.betrix-item-hockey4.noft.hockey-desktop .spans {
                        padding-top: 30px;
                    }

                    &.betrix-item.betrix-item-hockey.betrix-item-hockey1.ft.hockey-desktop .spans,
                    &.betrix-item.betrix-item-hockey.betrix-item-hockey2.ft.hockey-desktop .spans {
                        padding-top: 10px;
                    }

                    &.betrix-item.betrix-item-hockey.betrix-item-hockey3.ft.hockey-desktop .spans,
                    &.betrix-item.betrix-item-hockey.betrix-item-hockey4.ft.hockey-desktop .spans {
                        padding-top: 10px;
                    }

                    &.betrix-item.betrix-item-hockey.betrix-item-hockey1.ft.hockey-desktop .spans,
                    &.betrix-item.betrix-item-hockey.betrix-item-hockey3.ft.hockey-desktop .spans {
                        span {margin-left: 15px}
                    }

                    &.betrix-item.betrix-item-hockey.betrix-item-hockey2.ft.hockey-desktop .spans,
                    &.betrix-item.betrix-item-hockey.betrix-item-hockey4.ft.hockey-desktop .spans {
                        span {margin-left: -15px}
                    }

                    // this has to work only on mobile

                    &.betrix-item.betrix-item-hockey.betrix-item-hockey1.noft.hockey-mobile .spans,
                    &.betrix-item.betrix-item-hockey.betrix-item-hockey2.noft.hockey-mobile .spans,
                    &.betrix-item.betrix-item-hockey.betrix-item-hockey3.noft.hockey-mobile .spans,
                    &.betrix-item.betrix-item-hockey.betrix-item-hockey4.noft.hockey-mobile .spans {
                        padding-top: 10px;
                    }

                    &.betrix-item.betrix-item-hockey.betrix-item-hockey1.ft.hockey-mobile .spans,
                    &.betrix-item.betrix-item-hockey.betrix-item-hockey3.ft.hockey-mobile .spans {
                        span {margin-left: 8px}
                    }

                    &.betrix-item.betrix-item-hockey.betrix-item-hockey2.ft.hockey-mobile .spans,
                    &.betrix-item.betrix-item-hockey.betrix-item-hockey4.ft.hockey-mobile .spans {
                        span {margin-left: -8px}
                    }
                    @media screen and (orientation:landscape) {
                        &.betrix-item.betrix-item-hockey.betrix-item-hockey1.ft.hockey-mobile .spans,
                        &.betrix-item.betrix-item-hockey.betrix-item-hockey2.ft.hockey-mobile .spans,
                        &.betrix-item.betrix-item-hockey.betrix-item-hockey3.ft.hockey-mobile .spans,
                        &.betrix-item.betrix-item-hockey.betrix-item-hockey4.ft.hockey-mobile .spans {
                            padding-top: 11px;
                        }

                        &.betrix-item.betrix-item-hockey.betrix-item-hockey1.noft.hockey-mobile .spans,
                        &.betrix-item.betrix-item-hockey.betrix-item-hockey2.noft.hockey-mobile .spans,
                        &.betrix-item.betrix-item-hockey.betrix-item-hockey3.noft.hockey-mobile .spans,
                        &.betrix-item.betrix-item-hockey.betrix-item-hockey4.noft.hockey-mobile .spans {
                            padding-top: 30px;
                        }
    
                    }
                    span {
                        color: #333
                    }
                }

                &:nth-of-type(odd) {
                    .betrix-item:not(.noPr) {
                        &:nth-of-type(odd) {
                            background: rgba(0, 0, 0, 0.1);
                        }
                    }
                    .betrix-item.betrix-item-hockey {
                        &:nth-of-type(odd) {
                            background: rgba(0, 0, 255, 0.09);
                        }
                    }
                }

                &:nth-of-type(even) {
                    .betrix-item {
                        &:nth-of-type(even):not(.noPr) {
                            background: rgba(0, 0, 0, 0.1);
                        }
                    }
                    .betrix-item.betrix-item-hockey {
                        &:nth-of-type(even) {
                            background: rgba(0, 0, 255, 0.09);
                        }
                        span {
                            color: #333
                        }
                    }
                }
        
                .betrix-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    flex: 1;
                    cursor: pointer;

                    &.selected {
                        &.nopr { background: rgba(0, 145, 135, 0.6) !important;}

                        span {
                            &:last-child {
                                color: $white;
                            }
                        }
                    }

                    &.insured {
                        &.nopr {background: rgba(243, 139, 0, 0.6) !important;}

                        span {
                            &:last-child {
                                color: $white;
                            }
                        }
                    }

                    &.home-goals-7 {
                        span {
                            @include md {
                                font-size: 0.65rem;
                            }
                        }
                    }

                    &.home-goals-8 {
                        span {
                            @include md {
                                font-size: 0.6rem;
                            }
                        }
                    }

                    &.home-goals-9 {
                        span {
                            @include md {
                                font-size: 0.55rem;
                            }
                        }
                    }

                    &.score-disabled {
                        opacity: 0.7;
                        cursor: not-allowed;
                    }
    
                    span {
                        font-size: 0.9rem;
                        color: $white;

                        @include lg {
                            font-size: 0.7rem;
                        }

                        &:last-child {
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }

    .home-away-mobile-label {
        display: none;

        @include lg {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 1rem;
            height: 1rem;
            background: $green;

            .diagonal-line {
                position: absolute;
                top: 0.45rem;
                left: -0.2rem;
                width: 1.25rem;
                height: 1px;
                background: $white;
                transform: rotate(45deg);
            }

            .home {
                position: absolute;
                bottom: 0;
                left: 1px;
                color: $white;
                font-size: 0.45rem;
            }

            .away {
                position: absolute;
                top: 0;
                right: 1px;
                color: $white;
                font-size: 0.45rem;
            }
        }
    }
}