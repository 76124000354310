$black: #000000;
$lightBlack: #1B1B1E;
$hoverGrey: #2D2D32;
$darkGrey: #404046;
$grey: #82838B;
$white: #FBFFFE;
$orange: #F38B00;
$green: #009187;
$red: #BE0000;
$neon: #39ff14;
$lime: #32cd32;
$blue: #3792cb;
$grassgreen:#63AB62 // #76EE00