@import '../../../../styles/colors.scss';

.BetrixComboSystemSingle {
    .ss-container {
        margin: 15px 15px 0;

        .ss-markets {
            display: flex;
            justify-content: center;
            border-left: 1px solid $orange;
            border-right: 1px solid $orange;

            button {
                flex: 1;
                padding: 5px 0;
                border-top: 1px solid $orange;
                border-bottom: 1px solid $orange;
                color: $white;
                font-size: 13px;
                font-weight: 700;
                text-transform: uppercase;
    
                &.active,
                &:hover {
                    background: $orange;
                }
            }
        }

        .ss-question {
            margin: 15px 0;
            color: $white;
            font-size: 18px;
            text-align: center;
        }

        .ss-range {
            text-align: center;

            span {
                display: inline-block;
                vertical-align: top;
                width: calc(14.28% - 1px);
                margin-right: 1px;
                margin-bottom: 1px;
                padding: 5px 0;
                background: $darkGrey;
                color: $white;
                font-size: 15px;
                cursor: pointer;

                &:nth-of-type(7n + 7) {
                    margin-right: 0;
                }

                &:hover {
                    background: $hoverGrey;
                }

                &.selected {
                    background: $green;
                }

                &.insured {
                    background: $orange;
                }
            }
        }
        
        .ss-other-options {
            margin-top: 15px;
            text-align: center;

            div {
                display: inline-block;
                vertical-align: top;
                width: calc(33.33% - 1px);
                margin-right: 1px;
                margin-bottom: 1px;
                padding: 5px 0;
                background: $darkGrey;
                text-align: center;
                cursor: pointer;

                &:nth-of-type(3n + 3) {
                    margin-right: 0;
                }

                &.selected,
                &:hover {
                    background: $green;
                    font-weight: 700;

                    span {
                        &:nth-of-type(2) {
                            color: $black;
                        }
                    }
                }
                
                span {
                    display: inline-block;
                    vertical-align: top;
                    color: $white;
                    font-size: 14px;

                    &:nth-of-type(2) {
                        margin-left: 10px;
                        color: $orange;
                    }
                }
            }
        }

        .ss-selection {
            margin-top: 15px;

            .ss-label {
                margin: 0 0 5px;
                color: $white;
                text-align: center;
            }

            .ss-odds,
            .ss-max-stake {
                display: flex;
                justify-content: center;

                span {
                    &:nth-of-type(1) {
                        color: $white;
                    }

                    &:nth-of-type(2) {
                        margin-left: 10px;
                        color: $green;
                    }
                }
            }

            .ss-odds {
                margin-bottom: 5px;

                span {
                    font-weight: 700;
                }
            }
        }
    }
}