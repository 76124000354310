@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';

.BetrixQuickButtons {
    position: relative;
    padding: 1rem 0 1rem calc(3rem + 1px);

    @include lg {
        width: 100%;
        padding: 3rem 0 1rem 2.25rem;
    }
    .betrix-toggle {
        position: absolute;
        top: 8px;
        left: 0px;
        width: 1.8rem;
        color: $hoverGrey;
        text-align: center;
        @include lg {
            top: -20px;
            width: 1.25rem;
        }
        &.selected {
            color: $grey
        }
    }
    .any-other-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        top: 0;
        right: 0;
        padding-left: 2rem;
        left: 0rem;
        width: 100%;
        background: $lightBlack;

        @include lg {
            padding-left: 1.25rem;
            // left: 1.25rem;
            // width: calc(100% - 1.25rem);
        }

        .betrix-odds {
            // width: 150%;
            width: 66.6666%;
            text-align: left;

            span {
                font-size: 1rem;
                font-weight: 700;

                &:nth-of-type(1) {
                    color: $white;
                }

                &:nth-of-type(2) {
                    margin-left: 0.5rem;
                    color: $orange;
                }
                &:nth-of-type(3) {
                    margin-left: 0.5rem;
                    color: $white;
                    @include lg {
                        display: none;
                    }
                }
                &:nth-of-type(4) {
                    margin-left: 0.5rem;
                    color: $orange;
                    @include lg {
                        &:before {content: '('}
                        &:after {content: ')'}
                    }
                }

            }
            @include lg {
                span {
                    font-size: 0.9rem;
                    margin-left: -1rem;
                }
                width: 50%;
                // text-align: center;
            }
        }

        .animated .animate {
            -webkit-animation: BETRIX-ODDS 1s infinite; 
            -moz-animation:    BETRIX-ODDS 1s infinite; 
            -o-animation:      BETRIX-ODDS 1s infinite; 
            animation:         BETRIX-ODDS 1s infinite;
        }

        // @keyframes NAME-YOUR-ANIMATION 
        @-webkit-keyframes BETRIX-ODDS {
            0%, 49% {
                // background-color: rgb(117,209,63);
                color:$red
            }
            50%, 100% {
                // background-color: #e50000;
                color:$orange
            }
        }

        @keyframes BETRIX-ODDS {
            0%, 49% {
                // background-color: rgb(117,209,63);
                color:$red
            }
            50%, 100% {
                // background-color: #e50000;
                color:$orange
            }
        }

        &.aobsize3 .any-other-button {
            width: 66.666666666% !important;
        }
        
        .any-other-button {
            width: 33.33333333%;
            
            padding: 1rem 0;
            color: $white;
            &.aor-hockey {color: #333}
            font-size: 0.9rem;
            font-weight: 700;
            position: relative;
            overflow: hidden;
            img {
                position: absolute;
                width: 100%;
                top: 0;
                left: 0;
                @include lg {
                    height: 1.9rem
                }
            }
            div {
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: 0;
                background: transparent;
/*
                border: 4px solid rgba(255,255,255, 0.5);
                @include lg {
                    border: 2px solid rgba(255,255,255, 0.5);
                }
*/
                border-top: none;
                padding-top:0.45rem;

                @include lg {
                    padding-top:0.6rem;
                    @media (orientation: landscape) {
                        padding-top:0.5rem;
                    }
                    padding-bottom:0rem;
                }
            }

            &.aob1 div/*, &.aob1_ div*/ {
                border: 4px solid rgba(255,255,255, 0.5);
                @include lg {
                    border: 2px solid rgba(255,255,255, 0.5);
                }                
                border-right: none !important;
            }

            &.aob2 div/*, &.aob2_ div*/ {
                border: 4px solid rgba(255,255,255, 0.5);
                @include lg {
                    border: 2px solid rgba(255,255,255, 0.5);
                }                
                border-left: none !important;
                border-right: none !important;
            }

            &.aob3 div/*, &.aob3_ div*/ {
                border: 4px solid rgba(255,255,255, 0.5);
                @include lg {
                    border: 2px solid rgba(255,255,255, 0.5);
                }                
                border-left: none !important;
            }

            &.aob2 div /*, &.aob1_ div, &.aob3_ div*/ {
                background: rgba(0, 0, 0, 0.1)
            }
            &.aor-hockey.aob2 div {
                background: rgba(0, 0, 255, 0.09)
            }

            &.aob1 div, &.aob2 div, &.aob3 div/*, &.aob1_ div, &.aob2_ div, &.aob3_ div*/ {
                border-top: none;
            }


            &.selected div {
                background: rgba(0, 145, 135, 0.6) !important;
                border: 4px solid $green;
                @include lg {
                    border: 2px solid $green;
                }
                border-top: none;
            }
/*    
            &.selected.aob1, &.selected.aob1_ {
                border-right: none;
            }

            &.selected.aob2, &.selected.aob2_ {
                border-left: none;
                border-right: none;
            }

            &.selected.aob3, &.selected.aob3_ {
                border-left: none;
            }
*/
            &.insured div {
                background: rgba(243, 139, 0, 0.6) !important;
                border: 4px solid $orange;
                @include lg {
                    border: 2px solid $orange;
                }
                border-top: none;
            }
    
            &:disabled div {
                opacity: 0.5;
                cursor: not-allowed;
            }
            @include lg {
                left: 0rem;
                width: 66.6666%;
                font-size: 0.75rem;
            }
        }
    }
/*
    .any-other-button {
        position: absolute;
        top: 0;
        right: 0;
        width: 14rem;
        padding: 0.5rem 0;
        border: 2px solid $green;
        border-top: none;
        color: $white;
        font-size: 0.9rem;
        font-weight: 700;

        @include lg {
            left: 2.25rem;
            width: calc(100% - 2.25rem);
            font-size: 0.8rem;
        }

        &.selected {
            background: $green;
        }

        &.insured {
            background: $orange;
            border: 2px solid $orange;
            border-top: none;
        }

        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }
*/
    .show-hide-button {
        width: 11rem;
        height: 2rem;
        line-height: 1.8rem;
        margin-right: 1rem;
        border: 2px solid $grey;
        color: $white;
        font-weight: 700;

        &:hover {
            background: $hoverGrey;
        }
    }

    .expand-icon {
        position: absolute;
        top: 2.4rem;
        left: 1rem;
        color: $white;
        font-size: 1.5rem;
        opacity: 1;
        transition: opacity 0.5s ease-in-out;

        @include lg {
            top: 2.7rem;
            left: 0.4rem;
        }
        
        &.disabled {
            opacity: 0.5;
            cursor: not-allowed !important;
        }
    }

    .button-container {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s ease-in-out;
        margin-top: 1.4rem;
        @include lg {
            margin-top: 0rem;
        }
        &.visible {
            max-height: 700px;
        }

        .new-quick-button {
            border: 1px solid $white;
            display: inline-block;
            vertical-align: middle;
            height: 1.6rem;
            line-height: 1.5rem;
            // background: $grey;
            color: $white;
            font-size: 0.8rem;
            font-weight: 700;
            padding: 0 0.35rem;
            margin-bottom: 0.5rem;
            margin-right: 0.3rem;
            @include lg {
                height: 1.6rem;
                line-height: 1.5rem;
                font-size: 0.8rem;
                margin-bottom: 0.3rem;
                margin-right: 0.3rem;
                padding: 0 0.3rem;
            }
            // &:hover {color: $black;}
            &:hover,
            &.active {
                background: $hoverGrey;
            }

            &.selected {
                background: $green;
            }
            
            &:disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }
            
            &:last-of-type {
                margin-right: 0
            }
            // &:nth-of-type(3) {margin-right: 0.55rem;}
        }
        .market-button {
            display: inline-block;
            vertical-align: middle;
            width: 10rem;
            height: 2.2rem;
            line-height: 2.2rem;
            background: $grey;
            color: $white;
            font-size: 1rem;
            font-weight: 700;

            @include lg {
                height: 1.8rem;
                line-height: 1.8rem;
                font-size: 0.9rem;
            }

            &:hover {
                color: $black;
            }
        }

        .type-button {
            display: inline-block;
            vertical-align: middle;
            width: 6rem;
            height: 1.5rem;
            line-height: 1.4rem;
            margin-left: 1rem;
            border: 1px solid $white;
            color: $white;
            font-size: 0.85rem;

            @include lg {
                height: 1.3rem;
                line-height: 1.2rem;
                font-size: 0.75rem;
            }

            &:hover {
                background: $hoverGrey;
            }
        }

        .market-lines {
            display: inline-block;
            margin-top: 1rem;

            @include lg {
                display: block;
                margin-top: 0.75rem;
            }

            ~ .market-lines {
                margin-top: 0;
            }

            button {
                display: inline-block;
                vertical-align: top;
                width: 2.5rem;
                height: 1.5rem;
                line-height: 1.4rem;
                margin-bottom: 0.5rem;
                margin-right: 0.5rem; 
                border: 1px solid $grey;
                color: $white;
                font-size: 0.85rem;

                &:hover {
                    background: $hoverGrey;
                }

                &.selected {
                    background: $green;
                }
            }
        }

        .buttons-wrapper {
            display: flex;
            align-items: flex-start;
            margin-bottom: 2rem;
    
            @include lg {
                margin-bottom: 1.5rem;
            }
    
            .left-column {
                display: flex;
                align-items: center;
                margin-right: 1rem;
    
                @include lg {
                    margin-right: 0.5rem;
                }
    
                button {
                    width: 5rem;
                    height: 4.3rem;
                    padding: 0 0.55rem;
                    box-sizing: content-box;
                    background: $grey;
                    color: $white;
                    font-size: 1.12rem;
                    font-weight: 500;
                    cursor: default;
    
                    @include lg {
                        width: 4.25rem;
                        height: 3.7rem;
                        padding: 0 0.5rem;
                        background: $lightBlack;
                        font-size: 0.85rem;
                    }
                }
    
                .quick-button-active-true {
                    background: $green !important;
                }
            }
    
            .right-column {
                display: flex;
                flex-direction: column;
                justify-content: center;
    
                @include lg {
                    justify-content: space-between;
                }

                .button-lines {
                    button {
                        display: inline-block;
                        margin-top: 0.7rem;
                        width: 2.5rem;
                        padding: 0;
        
                        @include lg {
                            border: 1px solid $white;
                            margin-top: 0.5rem;
                        }
        
                        &:disabled {
                            background: transparent;
                            opacity: 0.5;
                            cursor: not-allowed;
                        }
        
                        &.selected {
                            background: $green;
        
                            @include lg {
                                background: $white;
                                color: $black;
                            }
                        }
        
                        &.insured {
                            background: $orange;
                        }
                    }
                }
    
                button {
                    height: 1.8rem;
                    line-height: 1.7rem;
                    margin-right: 0.5rem;
                    padding: 0 0.75rem;
                    border: 1px solid $grey;
                    color: $white;
                    font-size: 0.8rem;
                    font-weight: 500;
    
                    @include lg {
                        height: 1.6rem;
                        line-height: 1.3rem;
                        font-size: 0.75rem;
                    }
    
                    &:last-child {
                        margin-right: 0;
                    }
                }
    
                .additional-buttons {
                    display: flex;
    
                    @include lg {
                        margin-bottom: 0;
                    }
    
                    button {
                        @include lg {
                            border: 1px solid $green;
                        }
                    }
    
                    &.desktop {
                        button {
                            &:not(.selected) {
                                &:hover {
                                    background: $hoverGrey;
                                }
                            }
                        }
                    }
    
                    .selected {
                        background: $green;
                    }
                }
            }
        }
    }
}