@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';

.league-table-title {
    margin-bottom: 4px;

    @include xxl {
        margin-bottom: 3px;
    }

    @include xl {
        margin-bottom: 2px;       
    }

    @include l {
        margin-bottom: 1px;    
    }

    @include lg {
        margin-bottom: 0;
    }

    .league-title {
        padding: 7px 10px;
        background: $grey;

        @include xxl {
            padding: 6px 8px;
        }

        @include xl {
            padding: 5px 6px;   
        }

        @include lg {
            padding: 5px 8px;
        }

        img {
            width: 30px;
            height: 30px;
            margin-right: 10px;
            border-radius: 20px;

            @include xxl {
                width: 27px;
                height: 27px;
            }

            @include xl {
                margin-right: 8px;
                width: 24px;
                height: 24px;
            }

            @include l {
                margin-right: 6px;
                width: 21px;
                height: 21px;
            }

            @include lg {
                width: 24px;
                height: 24px;
            }
        }

        i {
            margin-right: 10px;
            color: $white;
            font-size: 30px;

            @include xxl {
                font-size: 27px;   
            }

            @include xl {
                font-size: 24px;
            }

            @include l {
                font-size: 21px;
            }

            @include lg {
                font-size: 24px;
            }
        }

        span {
            color: $white;
            font-size: 16px;
            font-weight: 700;

            @include xxl {
                font-size: 15px;   
            }

            @include xl {
                font-size: 14px;
            }

            @include l {
                font-size: 13px;
            }

            @include lg {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .main-markets {
        div {
            background: $darkGrey;
            text-align: center;
    
            span {
                color: $orange;
                font-size: 15px;
                font-weight: 700;

                @include xxl {
                    font-size: 14px;
                }

                @include xl {
                    font-size: 13px;
                }

                @include l {
                    font-size: 12px;
                }
            }
        }
    }
}