@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';

.betrix {
    &.hidden {display: none}
    .anti-betrix {
        position: absolute;
        // left: 580px;
        left: 430px;
        top: 18px;
        color: $white;
        z-index: 9999;
        font-weight: 700;
        font-size: 12px;
        @include lg {
            display: none
        }
    }

    .anti-betrix-checkbox {
        position: absolute;
        // left: 666px;
        left: 516px;
        top: 18px;
        @include lg {
            left: 205px;
            top: 9px;
        }
        color: $white;
        z-index: 9999;
        font-weight: 400;
        font-size: 12px
    }
    position: relative;
    display: flex;
    margin-bottom: 1.85rem;
    border: 1px solid $lightBlack;

    @include lg {
        flex-direction: column;
        margin-bottom: 0;
        border: none;
    }

    .main-betrix-wrapper {
        position: relative;
        width: 55%;
        z-index: 3;

        @include lg {
            width: 100%;
        }

        .background-wrapper {
            background: $black;
        }
    }

    .betrix-selection {
        display: flex;
        padding-top: 0.6rem;
        padding-left: 1.2rem;
        background: $black;

        &.disabled {
            div {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }

        &:not(.disabled) {
            .betrix-selection-option {
                &.selected {
                    background-color: $green;
                    .odds-wrapper {
                        span {
                            color: $white !important
                        }
                    }
                }
                &.insured {
                    background-color: $orange;
                    .odds-wrapper {
                        span {
                            color: $white !important
                        }
                    }
                }
            }
        }

        .betrix-selection-title {
            display: flex;
            align-items: center;
            width: 25%;
            padding: 0.65rem;
            border-right: 1px solid $black;
            background: $darkGrey;

            @include lg {
                padding: 0.3rem;
            }

            &.hover {
                cursor: pointer;
                background: $lightBlack;
                
                &:hover {
                    background: $hoverGrey;
                }
            }

            &.hide-other-options {
                width: 50%;
            }

            .label-wrapper {
                &.big {
                    span {
                        font-size: 0.95rem;

                        @include lg {
                            font-size: 0.65rem;
                        }
                    }
                }
                span {
                    display: block;
                    color: $white;
                    font-size: 0.8rem;
                    font-weight: 700;

                    @include lg {
                        font-size: 0.5rem;
                    }
                }
            }
            span {
                color: $white;
                font-size: 0.95rem;
                font-weight: 700;

                @include lg {
                    font-size: 0.65rem;
                }
            }

            .odds-wrapper {
                display: flex;
                align-items: center;
                margin-left: auto;

                span {
                    color: $orange;
                }
            }
        }
    }

    .banner-matches {
        flex-direction: column;
        justify-content: flex-start;
        width: 45%;
        padding: 2rem 4rem;

        @include lg {
            display: none;
        }

        .BannerMatch {
            width: 100%;
            margin-right: 0;
            margin-bottom: 2rem;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}