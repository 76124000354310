@import '../../styles/colors.scss';

.MultiSystemsPayout {
    .payouts-container {
        .predictions-row {
            display: flex;
            align-items: center;
            margin-bottom: 1px;

            span {
                width: 70px;
                color: $white;
            }

            .numbers-container {
                display: flex;
                align-items: center;
                width: calc(100% - 70px);
                margin-left: 10px;

                button {
                    flex: 1;
                    margin-right: 1px;
                    padding: 5px 0;
                    color: $white;
                    font-size: 14px;
                    overflow: hidden;

                    &:last-of-type {
                        margin-right: 0;
                    }

                    &.has-background {
                        background: $darkGrey;

                        &:not(:first-of-type) {
                            &:hover {
                                background: $hoverGrey;
                            }
                        }
                    }

                    &.selected {
                        background: $green;
                    }

                    &.insured {
                        background: $orange;
                    }

                    &.colored-selected {
                        color: $green;
                        font-weight: 700;
                    }

                    &.colored-insured {
                        color: $orange;
                    }

                    &.not-clickable {
                        cursor: default;
                    }
                }
            }
        }
    }

    .max-stake {
        display: flex;
        justify-content: center;

        span {
            &:first-of-type {
                color: $white;
            }
            &:last-of-type {
                margin-left: 10px;
                color: $green;
            }
        }
    }
}