@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';

.MarketScope {
    display: flex;
    align-items: center;
    margin-left: 1rem;

    @include lg {
        margin-left: 0.5rem;
    }

    .scope {
        padding: 0.1rem 0.3em;
        border: 1px solid #333;
        background: $white;
        font-size: 0.75rem;
        font-weight: 500;

        &:disabled {
            cursor: not-allowed;
        }

        &.active {
            background: $red;
            color: $white;
        }
    }
}