@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';

.suggested-markets-wrapper {
    position: absolute;
    top: 0;
    left: 20%;
    right: 0;
    width: 80%;
    height: 100%;
    border: 1px solid $darkGrey;
    overflow: auto;
    z-index: -1;
    transition: left 0.5s ease-in-out;

    @include lg {
        left: 100%;
        right: 0;
        width: 100%;
        height: calc(100vh - 3.5rem);
        margin-top: 3.5rem;
        border: none;
        overflow: unset;
        z-index: 1;
    }

    &.opened {
        left: 100%;
        width: calc(80% + 0.75rem);
        height: 100%;

        @include lg {
            left: 0;
            width: 100%;
            height: calc(100vh - 3.5rem);
            margin-top: 3.5rem;
        }
    }

    .mobile-overlay {
        display: none;

        @include lg {
            display: block;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.3);
        }
    }

    .data-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 0 1.25rem;
        background: $lightBlack;

        @include lg {
            position: absolute;
            top: 0;
            right: 0;
            width: 80%;
            height: auto;
            padding: 0.65rem;
            border-top: 2px solid $orange;
            border-bottom: 2px solid $orange;
            border-left: 2px solid $orange;
            background: $black;
        }

        .times {
            color: $white;
            font-size: 0.75rem;
            position: absolute;
            right: 3px;
            top: 1px;

            @include lg {
                display: none;
            }
        }
    
        h1 {
            margin: 1.75rem 0 2rem;
            color: $orange;
            font-size: 1.5rem;
            font-weight: 700;

            @include lg {
                display: none;
            }
        }
    
        .suggested-markets {
            width: 100%;
    
            .market-row {
                display: flex;
                justify-content: space-between;
                margin-bottom: 2px;
    
                @include lg {
                    justify-content: center;
                    margin-bottom: 0.3rem;
                }

                &:last-child {
                    margin-bottom: 0;
                }
    
                h3 {
                    margin: 0;
                    padding: 0.5rem 0;
                    color: $white;
                    font-size: 0.95rem;
                    font-weight: 500;

                    @include lg {
                        padding: 0.65rem 1.25rem 0.65rem 0.65rem;
                        margin-right: 0.3rem;
                        background: $darkGrey;
                        font-size: 0.65rem;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
    
                .market-odds {
                    display: flex;
                    justify-content: space-between;
                    width: 55%;
                    padding: 0.5rem;
                    background: $darkGrey;
                    cursor: pointer;

                    @include lg {
                        flex: 12 1;
                        padding: 0.65rem;
                        background: $lightBlack;
                    }
    
                    &.desktop {
                        &:hover,
                        &.active {
                            background: $orange;
    
                            span {
                                &:nth-of-type(1) {
                                    color: $black;   
                                }
    
                                &:nth-of-type(2) {
                                    color: $white;   
                                }
                            }                        
                        }
                    }

                    &.mobile {
                        &.active {
                            background: $orange;
    
                            span {
                                &:nth-of-type(1) {
                                    color: $black;   
                                }
    
                                &:nth-of-type(2) {
                                    color: $white;   
                                }
                            }                        
                        }
                    }
    
                    span {
                        color: $white;
                        font-size: 0.95rem;
                        font-weight: 700;

                        @include lg {
                            font-size: 0.65rem;
                        }
    
                        &:nth-of-type(1) {
                            margin-right: 0.65rem;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
    
                        &:nth-of-type(2) {
                            color: $orange;   
                        }
                    }
                }
            }
        }
    }
}