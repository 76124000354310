@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';

.betslip-mobile-icon {
    display: none;

    @include lg {
        display: block;
        position: fixed;
        bottom: 15px;
        right: 15px;
        padding: 15px;
        border-radius: 30px;
        background: $green;
        z-index: 2;
        opacity: 1;
        transition: opacity 0.5s ease-in-out;

        &.disabled {
            opacity: 0;
            z-index: 0;
        }

        img {
            display: block;
            width: 100%;
        }

        span {
            position: absolute;
            top: -5px;
            right: 0;
            padding: 3px 6px;
            border-radius: 20px;
            background: $white;
            color: $green;
            font-size: 12px;
            font-weight: 700;
        }
    }
}