@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';

.betslip {
    position: fixed;
    top: 110px;
    right: 0;
    bottom: 0;
    width: 20%;

    @include fhd {
        top: 90px;
    }

    @include xxl {
        top: 80px;
    }

    @include xl {
        top: 72px;
    }

    @include l {
        top: 65px;
    }

    @include lg {
        display: flex;
        flex-direction: column;
        top: unset;
        width: 100%;
        height: 0;
        z-index: 4;
        transition: height 0.5s ease-in-out;

        &.opened {
            height: 100vh;
        }
    }

    .betslip-overlay {
        display: none;

        @include lg {
            display: block;
            flex: 12 1;
            background: rgba(0, 0, 0, 0.7);
        }
    }

    .betslip-mode {
        display: none;
    }

    .betslip-wrapper {
        height: 100%;
        background: $black;

        @include lg {
            height: auto;
            max-height: 550px;
            overflow-y: auto;
            transition: max-height 0.5s ease-in-out;

            &.keyboard-opened {
                max-height: 350px;
            }
        }

        .betslip-title {
            position: relative;
            margin-bottom: 1px;
            padding: 5px 0;
            background: $green;

            @include xl {
                padding: 4px 0;
            }

            @include l {
                padding: 3px 0;
            }
    
            @include lg {
                position: sticky;
                top: 0;
                padding: 5px 0;
                z-index: 3;
            }
    
            h3 {
                margin: 0;
                color: $white;
                font-size: 18px;
                font-weight: 700;
                text-align: center;

                @include xxl {
                    font-size: 16px;
                }

                @include xl {
                    font-size: 15px;
                }

                @include l {
                    font-size: 14px;
                }

                @include lg {
                    font-size: 18px;
                }
            }
    
            .betslip-close {
                display: none;
    
                @include lg {
                    display: block;
                    position: absolute;
                    top: 50%;
                    right: 10px;
                    font-size: 20px;
                    color: $white;
                    transform: translateY(-50%);
                }
            }

            .betslip-history-icon {
                position: absolute;
                top: 50%;
                right: 10px;
                font-size: 20px;
                color: $white;
                transform: translateY(-50%);

                @include xxl {
                    font-size: 18px;
                }

                @include xl {
                    right: 8px;
                    font-size: 16px;
                }

                @include l {
                    font-size: 14px;
                }

                @include lg {
                    left: 10px;
                    right: unset;
                    font-size: 20px;
                }
            }
        }
    
        .clear-betslip {
            background: $darkGrey;
            margin-bottom: 1px;
            padding: 3px 10px;

            @include xxl {
                padding: 3px 8px;
            }

            @include xl {
                padding: 2px 7px;
            }

            @include l {
                padding: 2px 6px;
            }

            @include lg {
                padding: 3px 10px;
            }
            
            span {
                color: $white;
                font-size: 14px;
                cursor: pointer;

                @include xxl {
                    font-size: 13px;
                }

                @include xl {
                    font-size: 12px;
                }

                @include l {
                    font-size: 11px;
                }

                @include lg {
                    font-size: 14px;
                }
            }
        }
    
        .standard-mode {
            height: calc(100% - 63px);
        }

        .betslip-items {
            max-height: calc(100% - 44px);
            overflow-x: hidden;
            overflow-y: auto;
    
            @include lg {
                max-height: unset;
                overflow-y: none;
            }
    
            &::-webkit-scrollbar {
                width: 3px !important;
            }
            
            &::-webkit-scrollbar-track {
                background: $darkGrey;
            }
            
            &::-webkit-scrollbar-thumb {
                background: $orange;
            }
        }
    
        .odds-and-stake {
            margin-bottom: 1px;
            padding: 15px 20px 15px 35px;
            background: $lightBlack;

            @include xxl {
                padding: 12px 16px 12px 30px;
            }

            @include xl {
                padding: 10px 14px 10px 25px;
            }

            @include l {
                padding: 8px 12px 8px 20px;
            }

            @include lg {
                padding: 15px 20px 15px 35px;
            }
    
            .odds-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
    
                span {
                    font-size: 16px;

                    @include xxl {
                        font-size: 15px;
                    }

                    @include xl {
                        font-size: 14px;
                    }

                    @include l {
                        font-size: 13px;
                    }

                    @include lg {
                        font-size: 16px;
                    }
                    
                    &:first-child {
                        color: $grey;
                    }
    
                    &:last-child {
                        color: $orange;
                        font-weight: 700;
                    }
                }
            }
        }
    
        .place-bet-wrapper {
            background: $black;

            @include lg {
                position: sticky;
                bottom: 0;
            }

            .place-bet {
                width: 100%;
                padding: 10px 0;
                background: $orange;

                @include xxl {
                    padding: 8px 0;
                }

                @include xl {
                    padding: 6px 0;
                }

                @include l {
                    padding: 5px 0;
                }

                @include lg {
                    padding: 10px 0;
                }
        
                &.disabled {
                    opacity: 0.5;
                    cursor: not-allowed;
                }
        
                span {
                    font-size: 18px;
                    font-weight: 700;

                    @include xxl {
                        font-size: 16px;
                    }

                    @include xl {
                        font-size: 15px;
                    }

                    @include l {
                        font-size: 14px;
                    }

                    @include lg {
                        font-size: 18px;
                    }
        
                    &:first-child {
                        margin-right: 20px;
                        color: $white;

                        @include xxl {
                            margin-right: 18px;
                        }

                        @include xl {
                            margin-right: 16px;
                        }

                        @include l {
                            margin-right: 14px;
                        }

                        @include lg {
                            margin-right: 20px;
                        }
                    }

                    &:last-child {
                        color: $black;
                    }
                }
            }
        }
    
        .empty-betslip {
            display: block;
            line-height: 20px;
            padding: 25px;
            background: $lightBlack;
            color: $grey;
            font-size: 15px;
            font-weight: 700;
            text-align: center;

            @include xxl {
                line-height: 19px;
                padding: 20px;
                font-size: 14px;
            }

            @include xl {
                line-height: 18px;
                padding: 16px;
                font-size: 13px;
            }

            @include l {
                line-height: 17px;
                padding: 12px;
                font-size: 12px;
            }

            @include lg {
                line-height: 20px;
                padding: 25px;
                font-size: 15px;
            }
        }
    }
}