@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';

.PageBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    z-index: 1000;

    .content-wrapper {
        position: relative;
        padding: 1.9rem 3.1rem;
        border: 1px solid $green;
        background: $darkGrey;
        color: $white;
        text-align: center;

        p {
            margin: 0;
            color: $white;
            font-size: 1rem;
            font-weight: 400;
        }

        button {
            margin-top: 1rem;
        }
    }
}