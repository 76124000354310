@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';

.league-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    background: $grey;

    @include lg {
        grid-template-columns: repeat(1, 1fr);
    }

    .league {
        display: flex;
        align-items: center;
        padding: 6px 15px;

        @include xxl {
            padding: 4px 12px;
        }

        @include xl {
            padding: 3px 10px;
        }

        @include l {
            padding: 2px 8px;   
        }

        @include lg {
            padding: 5px 10px;
        }

        div {
            &.selected {
                i {
                    opacity: 1;
                }
            }

            &:not(.selected) {
                &:hover {
                    i {
                        opacity: 1;
                    }
                }
            }

            a {
                color: $white;
                font-size: 14px;
                font-weight: 700;
                text-decoration: none;

                @include xxl {
                    font-size: 13px;
                }

                @include xl {
                    font-size: 12px;
                }

                @include l {
                    font-size: 11px;
                }

                @include lg {
                    font-size: 12px;
                }
    
                &:hover {
                    color: $lightBlack;
                }
            }
    
            i {
                margin-left: 10px;
                color: $darkGrey;
                font-size: 14px;
                opacity: 0;

                @include xxl {
                    font-size: 13px;
                }

                @include xl {
                    margin-left: 8px;
                    font-size: 12px;
                }

                @include l {
                    font-size: 11px;
                }

                @include lg {
                    margin-left: 10px;
                    font-size: 12px;
                }
            }
        }
    }
}
