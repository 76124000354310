@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';

@keyframes pulse {
    0% {
        opacity: 0.3;
    }
    30% {
        opacity: 0.8;
    }
    100%{
        opacity: 0.3;
    }
}

.main-fader {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $green;
    z-index: 1000;
    
    .loader {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        
        svg {
            height: 200px;
            display: block;
            margin: 0 auto;

            @include lg {
                height: 120px;
            }
            
            path {
                animation-duration: 1s;
                animation-name: pulse;
                animation-iteration-count: infinite;
  
                &.path-7 {
                    animation-delay: -1s
                }
                
                &.path-6 {
                    animation-delay: -.875s
                }

                &.path-5 {
                    animation-delay: -.75s
                }

                &.path-4 {
                    animation-delay: -.625s
                }

                &.path-3 {
                    animation-delay: -.5s
                }

                &.path-2 {
                    animation-delay: -.375s
                }

                &.path-1 {
                    animation-delay: -.25s
                }
                
                &.path-0 {
                    animation-delay: -.125s
                }
            }
        }
    }
}