@import '../../../styles/colors.scss';

.bet-betslip-subitems {
    display: flex;
    margin-left: 46%;

    &.market-maker {
        flex-direction: column;

        div {
            span {
                &:last-child {
                    margin-left: 10px;
                    font-style: italic;
                }
            }

            &.selected {
                span {
                    color: $green;
                }
            }
            
            &.insured {
                span {
                    color: $orange;
                }
            }
        }
    }

    .matrix-wrapper {
        border: 1px solid $grey;

        .matrix-row {
            display: flex;

            &:last-child {
                div {
                    border-bottom: 1px solid $black;

                    &.selected {
                        border-bottom: 1px solid $green;
                    }
    
                    &.insured {
                        border-bottom: 1px solid $orange;
                    }
                }
            }
    
            div {
                width: 50px;
                height: 40px;
                line-height: 40px;
                border-right: 1px solid $grey;
                border-bottom: 1px solid $grey;
                color: $white;
                font-size: 14px;
                text-align: center;

                &:last-child {
                    border-right: 1px solid $black;

                    &.selected {
                        border-right: 1px solid $green;
                    }
    
                    &.insured {
                        border-right: 1px solid $orange;
                    }
                }

                &.selected {
                    background: $green;
                }

                &.insured {
                    background: $orange;
                }
            }
        }
    }
}