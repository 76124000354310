@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';

.tenant {
    position: absolute;
    top: 0px;
    right: 0px;
    color: $blue !important
}

img.tenant {
    top: 3px;
    right: -10px;
    width: 120px !important;
}

@media (max-width: 991px) {
    .sport-match .flag {top: 25px !important; right: 5px !important}
    .main-title {padding-bottom: 20px !important}
    .MarketGroupSelector {margin-top: 0 !important}
    img.tenant {
        top: 25px;
        left: 0px;
        right: auto;
        width: 120px !important;
        margin-right: 0px !important
    }
}
.lime {
    color: $lime !important
}

.main-title {
    .pre {
        white-space: pre;        
    }
    margin-bottom: 10px;
    position: relative;
    display: flex;
    align-items: center;
    line-height: 30px;
    @include xl {
        margin-bottom: 9px;
    }

    @include l {
        margin-bottom: 8px;
    }

    @include lg {
        display: block;
        margin: 0 10px;
        padding: 5px 0;
        white-space: nowrap;
        overflow-x: scroll;
    }

    &::-webkit-scrollbar {
        height: 0;
    }

    img {
        width: 20px;
        margin-right: 10px;

        @include xxl {
            width: 19px;
        }

        @include xl {
            margin-right: 8px;
            width: 18px;
        }

        @include l {
            margin-right: 6px;
            width: 17px;
        }

        @include lg {
            display: inline-block;
            vertical-align: top;
            margin-right: 5px;
            width: 16px;
        }
    }

    a, span, h1 {
        margin: 0;
        color: $white;
        font-size: 15px;
        font-weight: 700;

        @include xxl {
            font-size: 14px;
        }

        @include xl {
            font-size: 13px;
        }

        @include l {
            font-size: 12px;   
        }

        @include lg {
            display: inline-block;
            vertical-align: top;
            height: 16px;
            line-height: 16px;
            font-size: 13px;
        }
    }

    div {
        position: relative;
        display: flex;
        align-items: center;

        @include lg {
            display: inline-block;
            vertical-align: top;
        }

        i {
            color: $white;
            margin-left: 8px;
            font-size: 14px;

            @include xxl {
                font-size: 13px;
            }

            @include xl {
                margin-left: 6px;
                font-size: 12px;
            }

            @include l {
                margin-left: 5px;
                font-size: 11px;
            }

            @include lg {
                display: inline-block;
                vertical-align: top;
                height: 16px;
                line-height: 20px;
            }
        }

        .other-leagues-matches {
            flex-direction: column;
            position: absolute;
            top: 130%;
            left: -10px;
            background: $lightBlack;
            z-index: 4;
            white-space: nowrap;

            @include lg {
                position: fixed;
                top: 81px;
                left: 0;
                right: 0;
            }

            a {
                display: block;
                height: auto;
                line-height: unset;
                padding: 5px 20px 5px 10px;
                font-size: 15px;
                font-weight: 400;
                text-align: center;

                @include xxl {
                    padding: 5px 16px 5px 8px;
                    font-size: 14px;
                }

                @include xl {
                    padding: 4px 14px 4px 6px;
                    font-size: 13px;
                }

                @include l {
                    padding: 4px 12px 4px 5px;
                    font-size: 12px;
                }

                @include lg {
                    padding: 6px 0;
                    font-size: 13px;
                }
            }
        }
    }

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
/*
    span {
        // margin: 0 5px;

        @include xl {
            margin: 0 4px;   
        }

        @include lg {
            margin: 0 3px;
        }
    }
*/
    .quick-link-icon {
        display: none;

        @include lg {
            display: block;
            height: 16px;
            line-height: 16px;
            float: right;
            color: $grey;
            font-size: 16px;
        }
    }
}