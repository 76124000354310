@import '../../styles/colors.scss';

.betslip-history-table {
    .no-history-message {
        color: $white;
        font-size: 18px;
    }
        
    .table {
        width: 85%;
        border-spacing: 0;

        i {
            display: block;
        }

        .status {
            &.active {
                color: $green;
            }
            &.finished {
                color: $red;
            }
        }

        .table-header {
            div {
                padding: 5px;
                color: $white;
                font-size: 16px;
                font-weight: 700;
                text-align: center;
            }
        }

        .table-row {
            border-top: 1px solid $grey;

            div {
                padding: 10px 5px;
                color: $white;
                font-size: 14px;
                text-align: center;

                .search {
                    color: $white;
                    cursor: pointer;
                }
            }
        }
    }
}