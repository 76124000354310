@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';

.stake-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;

    @include xxl {
        margin-top: 12px;
    }

    @include xl {
        margin-top: 10px;
    }

    @include l {
        margin-top: 8px;
    }

    @include lg {
        margin-top: 15px;
    }

    .input-wrapper {
        display: flex;
        align-items: center;

        .betslip-input {
            width: 100px;
            padding: 5px 0;
            border: 1px solid $grey;
            color: $grey;
            font-size: 15px;
            text-align: center;

            @include xxl {
                width: 90px;
                font-size: 14px;
            }

            @include xl {
                width: 80px;
                padding: 4px 0;
                font-size: 13px;
            }

            @include l {
                width: 70px;
                padding: 3px 0;
                font-size: 12px;
            }

            @include lg {
                width: 100px;
                padding: 5px 0;
                font-size: 15px;

                &.active {
                    border: 1px solid $green;
                }
            }
        }

        span {
            margin-left: 10px;
            color: $grey;
            font-size: 16px;

            @include xxl {
                font-size: 15px;
            }

            @include xl {
                margin-left: 8px;
                font-size: 14px;
            }

            @include l {
                margin-left: 6px;
                font-size: 13px;
            }

            @include lg {
                margin-left: 10px;
                font-size: 16px;
            }
        }
    }

    .bet-wrapper {
        display: flex;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        margin-left: 10px;

        span {
            font-size: 15px;
            font-weight: 700;

            @include xxl {
                font-size: 14px;
            }

            @include xl {
                font-size: 13px;
            }

            @include l {
                font-size: 12px;
            }

            @include lg {
                font-size: 15px;
            }

            &:first-child {
                color: $grey;
            }

            &:last-child {
                color: $orange;
            }
        }
    }
}