@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';


.market-name {
    display: flex;
    margin: 0 0 1px;
    padding: 1rem;
    background: $darkGrey;
    color: $white;
    font-size: 0.95rem;
    font-weight: 700;
    &.thin {
        padding: 0.5rem 1rem;
    }

    .or-odds-insured {
        // text-align: center;
        position: absolute;
        top: 15px;
        // left: 0px;
        right: 160px;
        // margin-left: auto;
        // margin-right: auto;
        color: red
    }
    @include lg {
        padding: 0.5rem 0.75rem;
        font-size: 0.8rem;
    }

    .market-name-value {
        display: block;
        color: $white !important;
        div {float: left};
    }

    .invert-button {
        border: 1px solid $white;
        position: absolute;
        padding: 0.25rem 0.5rem 0.2rem;
        top: 11px;
        right: 10px;
        background: $black;
        font-size: 0.9rem;
        span {
            white-space: pre;
            &:nth-of-type(1) {
                color: $white !important;
            }
            &:last-child {
                color: #F38B00;
                font-weight: 700
            }
        }
        &:hover {
            background: $hoverGrey
        }

        @include lg {
            top: 4px
        }
    }
/*
    .invert-button {
        border: 1px solid $white;
        position: absolute;
        padding: 0.25rem 0.5rem 0.2rem;
        top: 11px;
        right: 10px;
        background: $black;
        span {
            white-space: pre;
            &:nth-of-type(1) {
                color: $white !important;
            }
        }
        &:hover {
            background: $hoverGrey
        }
        @include lg {
            top: 4px
        }
    }
*/
    .multi-selection-label {
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: 1rem;

        @include lg {
            margin-right: 0.75rem;
        }

        span {
            font-size: 0.95rem;
            font-weight: 700;

            @include lg {
                font-size: 0.8rem;
            }

            &:nth-of-type(1) {
                color: $white;
            }

            &:nth-of-type(2) {
                margin-left: 0.5rem;
                color: $orange;
            }
        }
    }

    .multi-selection-label {
        span {
            &:first-child {
                margin-right: 0.5rem;
                color: $white !important;
            }
        }
    }

    .market-info-button {
        margin-left: 0.75rem;
        font-size: 1rem;
    }

    &.marketmaker {
        background: $lightBlack;
        cursor: pointer;

        &.active {
            background: $green;

            .market-box {
                .scope {
                    color: $white
                }
                .market-maker {
                    color: $white
                }
            }
        }

        &:not(.active) {
            &:hover {
                background: $hoverGrey;
            }
        }
    }
}

.market-box {
    position: relative;

    @include lg {
        margin-bottom: 1px;
    }

    &.correct-score {
        .odds-wrapper {
            display: flex;
            flex-wrap: wrap;
            grid-template-columns: unset;
            grid-gap: unset;

            .single-odds {
                width: calc(12.5% - 1px);
                margin-right: 1px;
                margin-bottom: 1px;

                &:nth-of-type(8n + 8) {
                    width: 12.5%;
                    margin-right: 0;
                }

                &:last-of-type {
                    margin-right: 0;
                    flex-grow: 1;
                }
            }

            .single-odds-7 {
                width: calc(14.2857142857% - 1px);
                margin-right: 1px;
                margin-bottom: 1px;

                &:nth-of-type(8n + 8) {
                    width: calc(14.2857142857% - 1px);
                    margin-right: 1px;
                }

                &:nth-of-type(7n + 7) {
                    width: 14.2857142857%;
                    margin-right: 0;
                }

                &:last-of-type {
                    margin-right: 0;
                    flex-grow: 1;
                }
            }

            .single-odds-special.single-odds-7 {
                width: calc(28.5714285714% - 1px);
                margin-right: 1px;
                margin-bottom: 1px;

                &:nth-of-type(8n + 8) {
                    width: calc(28.5714285714% - 1px);
                    margin-right: 1px;
                }
                
                &:nth-of-type(7n + 7) {
                    width: 28.5714285714%;
                    margin-right: 0;
                }
            }

            .single-odds-6 {
                width: calc(16.66666666666% - 1px);
                margin-right: 1px;
                margin-bottom: 1px;

                &:nth-of-type(8n + 8) {
                    width: calc(16.66666666666% - 1px);
                    margin-right: 1px;
                }

                &:nth-of-type(6n + 6) {
                    width: 16.66666666666%;
                    margin-right: 0;
                }

                &:last-of-type {
                    margin-right: 0;
                    flex-grow: 1;
                }
            }

            .single-odds-special.single-odds-6 {
                width: calc(33.333333333333% - 1px);
                margin-right: 1px;
                margin-bottom: 1px;

                &:nth-of-type(8n + 8) {
                    width: calc(33.333333333333% - 1px);
                    margin-right: 1px;
                }
                
                &:nth-of-type(6n + 6) {
                    width: 33.333333333333%;
                    margin-right: 0;
                }
            }

            .single-odds-5 {
                width: calc(25% - 1px);
                margin-right: 1px;
                margin-bottom: 1px;

                &:nth-of-type(8n + 8) {
                    width: calc(25% - 1px);
                    margin-right: 1px;
                }

                &:nth-of-type(5n + 5) {
                    width: 25%;
                    margin-right: 0;
                }

                &:last-of-type {
                    margin-right: 0;
                    flex-grow: 1;
                }
            }

            .single-odds-special.single-odds-5 {
                width: calc(25% - 1px);
                margin-right: 1px;
                margin-bottom: 1px;

                &:nth-of-type(8n + 8) {
                    width: calc(25% - 1px);
                    margin-right: 1px;
                }
                
                &:nth-of-type(6n + 6) {
                    width: 25%;
                    margin-right: 0;
                }
            }
        }
    }

    &.wide {
        width: 100% !important;
        margin-right: 0 !important;

        @include lg {
            margin-bottom: 0;

            .odds-wrapper {
                display: block;

                .single-odds {
                    display: inline-block;
                    vertical-align: top;
                    width: 25%;
                    border-right: 1px solid $black;
                    border-bottom: 1px solid $black;

                    &:nth-of-type(4n + 4) {
                        border-right: 1px solid transparent;
                    }

                    span {
                        &:first-child {
                            float: left;
                        }
                        &:last-child {
                            float: right;
                        }
                    }
                }
            }
        }
    }

    .reset-market-maker {
        position: absolute;
        top: 0;
        left: 0;
        padding: 0.15rem 0.2rem;
        background: $red;
        color: $white;
        font-size: 0.9rem;
        z-index: 1;
    }

    // .scope {
    //     position: absolute;
    //     left: 15px;
    //     top: 5px;
    //     color: $white;
    //     font-size: 10px;
    //     font-weight: 600;
    //     letter-spacing: 1px;
    // }

    // .market-maker {
    //     position: absolute;
    //     left: 15px;
    //     top: 32px;
    //     color: $white;
    //     font-size: 10px;
    //     font-weight: 600;
    //     letter-spacing: 1px;
    // }

    .single-odds {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.active {
            background: $green
        }
        
        span {
            font-size: 0.95rem;
            
            @include lg {
                font-size: 0.8rem;
            }
            
            &:first-child {
                color: $grey;
            }

            &:last-child {
                color: $orange;
                font-weight: 700;
            }
        }
    }

    .odds-wrapper.odds-wrapper-disabled {
        .single-odds {
            background: $lightBlack;
            padding: 0.1rem 0.9rem;
            opacity: 0.3;
            cursor: default;
        }
    }

    .odds-wrapper.odds-wrapper-enabled {
        .single-odds {
            background: $lightBlack;
            padding: 0.5rem 0.9rem;
            // opacity: 0.3;
            // cursor: default;
        }
    }

    .odds-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
        grid-gap: 1px;

        .single-odds {
            &.any-other-result-single-odds1 {grid-column: span 10}
            &.any-other-result-single-odds2 {grid-column: span 9}
            &.any-other-result-single-odds3 {grid-column: span 8}
            &.any-other-result-single-odds4 {grid-column: span 7}
            &.any-other-result-single-odds5 {grid-column: span 6}
            &.any-other-result-single-odds6 {grid-column: span 5}
            &.any-other-result-single-odds7 {grid-column: span 4}
            &.any-other-result-single-odds8 {grid-column: span 3}
            &.any-other-result-single-odds9 {grid-column: span 2}
            display: flex;
            justify-content: space-between;
            padding: 1rem 0.9rem;
            background: $lightBlack;
            cursor: pointer;
            @include lg {
                padding: 0.5rem 0.75rem;
            }

            &:not(.disabled) {
                &.desktop {
                    &.empty {
                        &:hover {
                            background: $hoverGrey;
                        }
                    }
                    &.active {
                        background: $green;
                    }
                    &.insured {
                        background: $orange;
                    }
                    &.active,
                    &.insured {
                        span {
                            &:first-child {
                                color: $black;
                            }

                            &:last-child {
                                color: $white;
                            }
                        }
                    }
                }
                &.mobile {
                    &.active {
                        background: $green;
                    }
                    &.insured {
                        background: $orange;
                    }
                    &.active,
                    &.insured {
                        span {
                            &:first-child {
                                color: $black;
                            }

                            &:last-child {
                                color: $white;
                            }
                        }
                    }
                }
            }

            &.disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }

            span {
                font-size: 0.9rem;

                @include lg {
                    font-size: 0.8rem;
                }

                &:first-child {
                    flex: 12 1;
                    color: $white;
                    font-weight: 700;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &:last-child {
                    color: $orange;
                    font-weight: 900;
                }
            }

            &.align-center {
                justify-content: center;
            }
            &.align-center span {
                &:first-child {
                    display: none
                }
                &:last-child {
                    margin-left: 0
                }
            }
        }
    }

    .range-wrapper {
        margin-top: 0.1rem;
        padding: 1rem;
        background: $lightBlack;

        input {
            -webkit-appearance: none;
            width: 100%;
            padding: 0;
            height: 0.25rem;
            outline: none;
            border-radius: 5px;
            background: $white;
            cursor: pointer;
            
            &::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: 1.1rem;
                height: 1.1rem;
                border-radius: 10px;
                background: $orange;
                cursor: pointer;
            }
            
            &::-moz-range-thumb {
                width: 1.1rem;
                height: 1.1rem;
                border-radius: 10px;
                background: $orange;
                cursor: pointer;
            }
        }
    }

    .market-menu {
        height: 1rem;
        display: flex;
        background: $darkGrey;
        border-bottom: 1px solid $black;
        .blank {
            display: grid;
            width: 3.75rem;
            font-size: 0.8rem;
            @include lg {
                font-size: 0.75rem;
                width: 2.5rem;
            }
            border-right: 1px solid $black;
            color: #fff;
            font-weight: 400;
            text-align: center
        }
        .values {
            width: calc(100% - 3.75rem);
            font-size: 0.8rem;
            @include lg {
                font-size: 0.75rem;
                width: calc(100% - 2.5rem);
            }
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
            grid-gap: 1px;
            color: #fff;
            font-weight: 700;
            background: $black;
            div {
                display: flex;
                justify-content: center;
                padding: 0rem 0.9rem;
                background: $darkGrey;
            }
        }
    }

    .value-wrapper {
        display: flex;

        &.first {
            .value-item {
                position: relative;
                cursor: pointer;

                .arrow-down {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    background: $black;
                    border-top: 0.75rem solid $orange;
                    border-left: 1.9rem solid transparent;
                    border-right: 1.9rem solid transparent;

                    @include lg {
                        border-top: 0.5rem solid $orange;
                        border-left: 1.25rem solid transparent;
                        border-right: 1.25rem solid transparent;
                    }
                }
            }
        }

        .value-item {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 3.75rem;
            background: $orange;
            color: $white;

            @include lg {
                width: 2.5rem;
            }

            .value {
                font-size: 0.9rem;
                font-weight: 700;
    
                @include lg {
                    font-size: 0.7rem;
                }
            }
        }

        .odds-wrapper {
            width: calc(100% - 3.75rem);

            @include lg {
                width: calc(100% - 2.5rem);
            }
        }
    }

    .other-options {
        display: none;
        z-index: 1;

        &.opened {
            display: block;
            margin-top: 1px;
        }

        .value-wrapper {
            background: $black;
            margin-bottom: 1px;
        }
    }
}